import React from 'react';

export const OrderInfo = () => {
    return <section id="order-information" className="bg-success py-5">
    <div className="container">
        <h2 className="sr-only">Order information</h2>

        <div className="row">
            <div className="col-12 col-md-6 text-white mb-4 mb-md-0">
                <h3 className="mb-2">Order online</h3>
                <p>Products can be purchased online using your credit or debit card or PayPal. No account is required.</p>
            </div>

            <div className="col-12 col-md-6 text-white mb-4 mb-md-0">
                <h3 className="mb-2">Delivery details</h3>
                <p>Please allow up to 21 days for delivery, however we endeavour to despatch items within two working days.</p>
            </div>
        </div>
    </div>
</section>
}
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { Formik, Form } from "formik";

import {
  setBillingAddress,
  updateBillingAddress,
  billingSameAsShipping,
} from "../../../redux/payment/payment.actions";

import { selectCurrentCustomer } from "../../../redux/customer/customer.selectors";
import { selectCurrentShipping } from "../../../redux/shipping/shipping.selectors";
import { selectCurrentPayment } from "../../../redux/payment/payment.selectors";
import { selectCartItems } from "../../../redux/cart/cart.selectors";

import * as Yup from "yup";

import { countries } from "../../../config/countries";
import ShippingActionTypes from "../../../redux/shipping/shipping.types";

const API_URI = process.env.REACT_APP_API_URI;

const DeliveryAddressSchema = Yup.object().shape({
  name: Yup.string().required("Name is required."),
  country: Yup.string().required("Country is required."),
  address_line_1: Yup.string().required("Address line 1 is required."),
  //address_line_2: Yup.string().required("Name is required."),
  city: Yup.string().required("City is required."),
  //county: Yup.string().required("Name is required."),
  postcode: Yup.string().required("Postcode is required."),
  country: Yup.string().required("Country is required."),
});

const BillingAddress = ({
  shipping,
  payment,
  setBillingAddress,
  updateBillingAddress,
  billingSameAsShipping,
}) => {
  const initialValues = {
    name: "",
    country: "GB",
    address_line_1: "",
    address_line_2: "",
    city: "",
    county: "",
    postcode: "",
  };

  if (payment.set_billing_address === false) {
    return (
      <div>
        <h6 className="mb-3 mt-3">Billing details:</h6>
        <Formik
          initialValues={initialValues}
          validationSchema={DeliveryAddressSchema}
          onSubmit={(values, actions) => {
            // setLoading(true);
            setBillingAddress(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <Form noValidate autoComplete="off">
              {shipping.method === "delivery" ? (
                <div className="form-group row">
                  <label htmlFor="name" className="col-sm-3 col-form-label">
                    Same as Delivery Details
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="checkbox"
                      checked={payment.billing_same_as_shipping}
                      onChange={() => billingSameAsShipping(shipping.delivery_address)}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="form-group row">
                <label htmlFor="name" className="col-sm-3 col-form-label">
                  Name <span className="text-danger">*</span>
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    id="name"
                    placeholder="Name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.name && touched.name ? (
                    <small className="text-danger">{errors.name}</small>
                  ) : null}
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="country" className="col-sm-3 col-form-label">
                  Country <span className="text-danger">*</span>
                </label>
                <div className="col-sm-9">
                  <select
                    className="form-control"
                    name="country"
                    value={values.country}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value="" label="Select country" />
                    {countries.map((country, index) =>
                      country ? (
                        <option
                          key={index}
                          value={country.code}
                          label={country.label}
                        />
                      ) : (
                        ""
                      )
                    )}
                  </select>
                  {errors.country && touched.country ? (
                    <small className="text-danger">{errors.country}</small>
                  ) : null}
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="address_line_1"
                  className="col-sm-3 col-form-label"
                >
                  Address Line 1 <span className="text-danger">*</span>
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    name="address_line_1"
                    className="form-control"
                    id="address_line_1"
                    placeholder="Address Line 1"
                    value={values.address_line_1}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.address_line_1 && touched.address_line_1 ? (
                    <small className="text-danger">
                      {errors.address_line_1}
                    </small>
                  ) : null}
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="address_line_2"
                  className="col-sm-3 col-form-label"
                >
                  Address Line 2
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    name="address_line_2"
                    className="form-control"
                    id="address_line_2"
                    placeholder="Address Line 2"
                    value={values.address_line_2}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.address_line_2 && touched.address_line_2 ? (
                    <small className="text-danger">
                      {errors.address_line_2}
                    </small>
                  ) : null}
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="city" className="col-sm-3 col-form-label">
                  City <span className="text-danger">*</span>
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    name="city"
                    className="form-control"
                    id="city"
                    placeholder="City"
                    value={values.city}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.city && touched.city ? (
                    <small className="text-danger">{errors.city}</small>
                  ) : null}
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="county" className="col-sm-3 col-form-label">
                  County
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    name="county"
                    className="form-control"
                    id="county"
                    placeholder="County"
                    value={values.county}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.county && touched.county ? (
                    <small className="text-danger">{errors.county}</small>
                  ) : null}
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="postcode" className="col-sm-3 col-form-label">
                  Postcode <span className="text-danger">*</span>
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    name="postcode"
                    className="form-control"
                    id="postcode"
                    placeholder="Postcode"
                    value={values.postcode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.postcode && touched.postcode ? (
                    <small className="text-danger">{errors.postcode}</small>
                  ) : null}
                </div>
              </div>

              <div className="form-group row">
                <div className="offset-sm-3 col-sm-9">
                  <button type="submit" className="btn btn-grey">
                    Continue
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  } else {
    return (
      <div className="mb-3 mt-3">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <h6>Bill to:</h6>

            {payment.billing_same_as_shipping ? (
              <div>Same as Delivery Details</div>
            ) : (
              <div>
                <div>{payment.billing_address.name}</div>
                <div>{payment.billing_address.address_line_1}</div>
                <div>{payment.billing_address.address_line_2}</div>
                <div>{payment.billing_address.city}</div>
                <div>{payment.billing_address.county}</div>
                <div>{payment.billing_address.postcode}</div>
                <div>
                  {payment.billing_address.country
                    ? countries.find(
                        (country) =>
                          country &&
                          country.code === payment.billing_address.country
                      ).label
                    : ""}
                </div>
                <div>{payment.billing_address.phone}</div>
              </div>
            )}

            <button
              type="button"
              className="btn btn-sm btn-grey"
              onClick={() => updateBillingAddress()}
            >
              Change
            </button>
          </div>
        </div>
      </div>
    );
  }
};

const mapStateToProps = createStructuredSelector({
  shipping: selectCurrentShipping,
  payment: selectCurrentPayment,
});

const mapDispatchToProps = (dispatch) => ({
  setBillingAddress: (address) => dispatch(setBillingAddress(address)),
  updateBillingAddress: () => dispatch(updateBillingAddress()),
  billingSameAsShipping: (address) => dispatch(billingSameAsShipping(address)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BillingAddress)
);

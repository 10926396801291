import React from "react";
import { Helmet } from "react-helmet";
import "./checkout.styles.scss";

const OrderCompletePage = () => {
  return (
    <div>
      <Helmet>
        <title>Order completed | Trinity House</title>
      </Helmet>
      <div className="message" role="alert">
        Your order has been successfully completed.
      </div>
    </div>
  );
};

export default OrderCompletePage;


import PaymentActionTypes from './payment.types';


export const setPaymentMethod = method => ({
  type: PaymentActionTypes.SET_PAYMENT_METHOD,
  payload: method
});

export const setBillingAddress = data => ({
  type: PaymentActionTypes.SET_BILLING_ADDRESS,
  payload: data
});

export const updateBillingAddress = () => ({
  type: PaymentActionTypes.UPDATE_BILLING_ADDRESS,
});

export const billingSameAsShipping = data => ({
  type: PaymentActionTypes.BILLING_SAME_AS_SHIPPING,
  payload: data
});

export const resetPayment = () => ({
  type: PaymentActionTypes.RESET_PAYMENT
});

  


        
  
 




import React from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createStructuredSelector } from "reselect";

// import DeliveryAddress from "./delivery/delivery-address.component";
// import DeliveryOptions from "./delivery/delivery-options.component";

// import CollectionAddress from "./collection/collection-address.component";
// import CollectionOptions from "./collection/collection-options.component";

import StripePayment from './stripe/stripe-payment.component';
import PaypalPayment from './paypal/paypal-payment.component';

import {
  setPaymentMethod,
  setBillingAddress,
  resetPayment,
} from "../../redux/payment/payment.actions";

import { selectCurrentPayment } from "../../redux/payment/payment.selectors";
import { selectShippingCost } from "../../redux/shipping/shipping.selectors";
import { selectCartTotal } from '../../redux/cart/cart.selectors';


const PaymentMethod = ({
  cartTotal,
  shippingCost,
    payment,
    setPaymentMethod
}) => {
  return (
    <div className="bg-white px-5 py-3 mt-4">
      <h6 className="mb-3 mt-3">PAYMENT METHOD</h6>
      <button
        type="button"
        className={`btn btn-blue ${
          payment.method === "card" ? " active" : ""
        }`}
        onClick={() => setPaymentMethod('card')}
      >
        Credit / Debit card
      </button>
      <button
        type="button"
        className={`btn btn-blue ml-3 ${
            payment.method === "paypal" ? " active" : ""
        }`}
        onClick={() => setPaymentMethod('paypal')}
      >
        PayPal
      </button>





{payment.method === 'card' ? <StripePayment /> : ''}
{payment.method === 'paypal' ? <PaypalPayment /> : ''}









    </div>
  );
}


const mapStateToProps = createStructuredSelector({
    cartTotal: selectCartTotal,
    shippingCost: selectShippingCost,
    payment: selectCurrentPayment,
  });
  
  const mapDispatchToProps = (dispatch) => ({
    setPaymentMethod: (method) => dispatch(setPaymentMethod(method)),
    resetPayment: () => dispatch(resetPayment()),

  });
  
  export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(PaymentMethod)
  );
  

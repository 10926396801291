import axios from 'axios';
import ShippingActionTypes from './shipping.types';
import { countries } from '../../config/countries';


const API_URI = process.env.REACT_APP_API_URI;

export const setShippingMethod = method => ({
  type: ShippingActionTypes.SET_SHIPPING_METHOD,
  payload: method
});

export const getShippableCountries = () => {

 // console.log('test >>>');

  return function(dispatch) {

    //console.log('test 33')

    return axios.get(API_URI + '/shipping-zones/shippable-countries')
  .then((response) => {
    console.log(response.data)
    const shippableCountires = response.data.data;

    return shippableCountires.map((country_code) => countries.find(item => item.code === country_code));



  }).then(data => {
    dispatch({
      type: ShippingActionTypes.GET_SHIPPABLE_COUNTRIES,
      payload: data
    });
    //return data;
  })
  .catch((error) => {
    console.log(error);
  });
  }

  console.log('end >>>')
}

export const setDeliveryAddress = (data) => ({
  type: ShippingActionTypes.SET_DELIVERY_ADDRESS,
  payload: {address:data.address, items:data.items}
});

// export const getDeliveryOptions = (address, items) => ({
//   type: ShippingActionTypes.GET_DELIVERY_OPTIONS,
//   payload: {address:address, items:items}
// });

export const updateDeliveryAddress = () => ({
  type: ShippingActionTypes.UPDATE_DELIVERY_ADDRESS
});


export const getDeliveryOptions = data => {

  return function(dispatch) {
    return axios.post(API_URI + '/delivery-options', data)
  .then((response) => {

    console.log(response.data);
      return response.data.data;
  }).then(data => {
    dispatch({
      type: ShippingActionTypes.GET_DELIVERY_OPTIONS,
      payload: data,
    });
    return data;
  })
  .catch((error) => {return error;});
  }
}

export const setDeliveries = data => ({
  type: ShippingActionTypes.SET_DELIVERIES,
  payload: data
});

export const updateDeliveryOption = () => ({
  type: ShippingActionTypes.UPDATE_DELIVERY_OPTION
});

export const getAvailableCollectionAddresses = () => {

  return function(dispatch) {
    return axios.get(API_URI + '/active-collection-points')
  .then((response) => {
    return response.data.data;
  }).then(data => {
    dispatch({
      type: ShippingActionTypes.GET_AVAILABLE_COLLECTION_ADDRESSES,
      payload: data
    });
   // return data;
  })
  .catch((error) => {return error;});
  }
}

export const setCollectionAddress = (address) => ({
  type: ShippingActionTypes.SET_COLLECTION_ADDRESS,
  payload: address
});

export const updateCollectionAddress = () => ({
  type: ShippingActionTypes.UPDATE_COLLECTION_ADDRESS
});

export const getCollectionOptions = data => {

  return function(dispatch) {
    return axios.post(API_URI + '/collection-options', data)
  .then((response) => {
    return response.data.data;
  }).then(data => {
    dispatch({
      type: ShippingActionTypes.GET_COLLECTION_OPTIONS,
      payload: data
    });
    return data;
  })
  .catch((error) => {return error;});
  }
}

export const setCollectionOption = option => ({
  type: ShippingActionTypes.SET_COLLECTION_OPTION,
  payload: option
});

export const updateCollectionOption = () => ({
  type: ShippingActionTypes.UPDATE_COLLECTION_OPTION
});



export const resetShipping = () => ({
  type: ShippingActionTypes.RESET_SHIPPING
});

  


        
  
 




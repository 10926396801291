import CustomerActionTypes from './customer.types';
import { setCustomer } from './customer.actions';

const INITIAL_STATE = {
  set_customer: false,
  details: [],
};

const customerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    
    case CustomerActionTypes.SET_CUSTOMER:
      return {
        ...state,
        set_customer: true,
        details: action.payload
      };
    case CustomerActionTypes.CHANGE_CUSTOMER: 
    return {
      ...state,
      set_customer: false,
    }
    case CustomerActionTypes.RESET_CUSTOMER:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default customerReducer;

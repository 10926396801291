import React from 'react';

import { OrderInfo } from '../components/shared/order-info.component';
import UnlistedCategories from '../components/categories/unlisted-category-list.component';
import ProductDetails from '../components/products/product-details.component';
import ProductRelated from '../components/products/product-related.component';
import { CovidNotice } from '../components/shared/covid-notice.component';

const UnlistedProductPage = (props) => {

  const productId = props.match.params.productId;

  return <main role="main" id="main">
      {/* <div className="bg-light">
        <UnlistedCategories />
      </div> */}
      <ProductDetails key={'product-' + productId} productId={productId} />
    </main>
}

export default UnlistedProductPage;
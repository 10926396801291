import React, { useEffect } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import './App.css';
import './sass/main.scss';

import Header from './components/shared/header.component';
import Footer from './components/shared/footer.component';
import HomePage from './pages/home.page';
import LCPDepositsPage from './pages/lcp.page';
import LCPItemPage from './pages/lcp-item.page';
import CategoryPage from './pages/category.page';
import UnlistedCategoryPage from './pages/unlisted-category.page';
import UnlistedCategoriesPage from './pages/unlisted-categories.page';
import ProductPage from './pages/product.page';
import UnlistedProductPage from './pages/unlisted-product.page';
import CartPage from './pages/cart.page';
import OrderCompletePage from './pages/order-complete.page';
import CheckoutPage from './pages/checkout.page';

  // add action to all snackbars
  const notistackRef = React.createRef();
  const onClickDismiss = key => () => { 
      notistackRef.current.closeSnackbar(key);
  }



function App() {
  let history = useHistory();
  useEffect(() => {
    let unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => unlisten() 
  })

  return (
    <SnackbarProvider maxSnack={3}
      ref={notistackRef}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
    }}
    action={(key) => (
        <IconButton onClick={onClickDismiss(key)}><CloseIcon fontSize="small" style={{color: 'white' }}/></IconButton>
    )}>
      <Header/>
      <Switch>
        <Route exact path='/' component={ HomePage } />
        <Route exact path='/order-complete' component={ OrderCompletePage } />
        <Route exact path='/lcp/:productUrl' component={ LCPItemPage } />
        <Route exact path='/unlisted-categories/:categoryId' component={ UnlistedCategoryPage } />
        <Route exact path='/unlisted-categories' component={ UnlistedCategoriesPage } />
        <Route exact path='/categories/:categoryId' component={ CategoryPage } />
        <Route exact path='/unlisted-products/:productId' component={ UnlistedProductPage } />
        <Route exact path='/products/:productId' component={ ProductPage } />
        <Route exact path='/cart' component={ CartPage } />
        <Route exact path='/checkout' component={ CheckoutPage } />
      </Switch>
      <Footer/>
    </SnackbarProvider>
  );
}

const mapDispatchToProps = dispatch => ({
 
});

export default connect(null, mapDispatchToProps)(App);

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { Formik, Form } from "formik";
import axios from "axios";

import DeliveryOptions from "./delivery-options.component";

import {
  setCustomer,
  resetCustomer,
} from "../../../redux/customer/customer.actions";

import {
  setShippingMethod,
  setDeliveryAddress,
  updateDeliveryAddress,
  resetShippingMethod,
  getDeliveryOptions,
  getShippableCountries,
  updateDeliveryOption,
} from "../../../redux/shipping/shipping.actions";

import { selectCurrentCustomer } from "../../../redux/customer/customer.selectors";
import { selectCurrentShipping } from "../../../redux/shipping/shipping.selectors";
import { selectCartItems } from "../../../redux/cart/cart.selectors";

import * as Yup from "yup";

const API_URI = process.env.REACT_APP_API_URI;

const DeliveryAddressSchema = Yup.object().shape({
  name: Yup.string().required("Name is required."),
  country: Yup.string().required("Country is required."),
  address_line_1: Yup.string().required("Address line 1 is required."),
  //address_line_2: Yup.string().required("Name is required."),
  city: Yup.string().required("City is required."),
  //county: Yup.string().required("Name is required."),
  postcode: Yup.string().required("Postcode is required."),
  phone: Yup.string().required("Phone is required."),
});

const DeliveryAddress = ({
  items,
  customer,
  setCustomer,
  resetCustomer,
  shipping,
  setDeliveryAddress,
  updateDeliveryAddress,
  getShippableCountries,
  updateDeliveryOption,
}) => {
  // getShippableCountries();
  const [loadCountries, setLoadCountries] = React.useState(true);

  React.useEffect(() => {
    if (loadCountries) {
      getShippableCountries();
      setLoadCountries(false);
    }
  }, [loadCountries]);

  const initialValues = {
    name: shipping.delivery_address.name,
    country:
      shipping.delivery_address.country === undefined
        ? "GB"
        : shipping.delivery_address.country,
    address_line_1: shipping.delivery_address.address_line_1,
    address_line_2: shipping.delivery_address.address_line_2,
    city: shipping.delivery_address.city,
    county: shipping.delivery_address.county,
    postcode: shipping.delivery_address.postcode,
    phone: shipping.delivery_address.phone,
  };

  if (shipping.set_delivery_address === false) {
    return (
      <div>
        <h6 className="mb-3 mt-3">DELIVERY</h6>
        <Formik
          initialValues={initialValues}
          validationSchema={DeliveryAddressSchema}
          onSubmit={(values, actions) => {
            setDeliveryAddress({ address: values, items: items });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <Form noValidate autoComplete="off">
              <fieldset>
                <legend>Please provide delivery address:</legend>
              <div className="form-group row">
                <label htmlFor="name" className="col-sm-3 col-form-label">
                  Name <span className="text-danger">*</span>
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    id="name"
                    placeholder="Name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.name && touched.name ? (
                    <small className="text-danger">{errors.name}</small>
                  ) : null}
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="country" className="col-sm-3 col-form-label">
                  Country <span className="text-danger">*</span>
                </label>
                <div className="col-sm-9">
                  <select
                    className="form-control"
                    name="country"
                    id="country"
                    value={values.country}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value="" label="Select country" />

                    {shipping.shippable_countries.length > 0
                      ? shipping.shippable_countries.map((country, index) =>
                          country ? (
                            <option
                              key={index}
                              value={country.code}
                              label={country.label}
                              title={country.label}
                            />
                          ) : (
                            ""
                          )
                        )
                      : ""}
                  </select>
                  {errors.country && touched.country ? (
                    <small className="text-danger">{errors.country}</small>
                  ) : null}
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="address_line_1"
                  className="col-sm-3 col-form-label"
                >
                  Address Line 1 <span className="text-danger">*</span>
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    name="address_line_1"
                    className="form-control"
                    id="address_line_1"
                    placeholder="Address Line 1"
                    value={values.address_line_1}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.address_line_1 && touched.address_line_1 ? (
                    <small className="text-danger">
                      {errors.address_line_1}
                    </small>
                  ) : null}
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="address_line_2"
                  className="col-sm-3 col-form-label"
                >
                  Address Line 2
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    name="address_line_2"
                    className="form-control"
                    id="address_line_2"
                    placeholder="Address Line 2"
                    value={values.address_line_2}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.address_line_2 && touched.address_line_2 ? (
                    <small className="text-danger">
                      {errors.address_line_2}
                    </small>
                  ) : null}
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="city" className="col-sm-3 col-form-label">
                  City <span className="text-danger">*</span>
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    name="city"
                    className="form-control"
                    id="city"
                    placeholder="City"
                    value={values.city}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.city && touched.city ? (
                    <small className="text-danger">{errors.city}</small>
                  ) : null}
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="county" className="col-sm-3 col-form-label">
                  County
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    name="county"
                    className="form-control"
                    id="county"
                    placeholder="County"
                    value={values.county}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.county && touched.county ? (
                    <small className="text-danger">{errors.county}</small>
                  ) : null}
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="postcode" className="col-sm-3 col-form-label">
                  Postcode <span className="text-danger">*</span>
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    name="postcode"
                    className="form-control"
                    id="postcode"
                    placeholder="Postcode"
                    value={values.postcode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.postcode && touched.postcode ? (
                    <small className="text-danger">{errors.postcode}</small>
                  ) : null}
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="phone" className="col-sm-3 col-form-label">
                  Phone <span className="text-danger">*</span>
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    name="phone"
                    className="form-control"
                    id="phone"
                    placeholder="Phone"
                    value={values.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.phone && touched.phone ? (
                    <small className="text-danger">{errors.phone}</small>
                  ) : null}
                </div>
              </div>

              <div className="form-group row">
                <div className="offset-sm-3 col-sm-9">
                  <button type="submit" className="btn btn-blue">
                    Continue
                  </button>
                </div>
              </div>
              </fieldset>
            </Form>
          )}
        </Formik>
      </div>
    );
  } else {
    return (
      <div className="mb-3 mt-3">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <h6>Delivered to:</h6>

            <div>{shipping.delivery_address.name}</div>
            <div>{shipping.delivery_address.address_line_1}</div>
            <div>{shipping.delivery_address.address_line_2}</div>
            <div>{shipping.delivery_address.city}</div>
            <div>{shipping.delivery_address.county}</div>
            <div>{shipping.delivery_address.postcode}</div>
            <div>
              {shipping.shippable_countries.length > 0
                ? shipping.shippable_countries.find(
                    (country) =>
                      country &&
                      country.code === shipping.delivery_address.country
                  ).label
                : ""}
            </div>
            <div>{shipping.delivery_address.phone}</div>

            <button
              type="button"
              className="btn btn-sm btn-grey"
              onClick={() => updateDeliveryAddress()}
            >
              Change
            </button>
          </div>

          {shipping.set_deliveries ? (
            <div className="col-md-6 col-sm-12">
              <h6>Deliveries:</h6>
              {shipping.deliveries.map((delivery, delivery_index) => {
                return (
                  <div key={delivery_index} className="package-delivery">
                    <div>
                      Delivery {delivery_index + 1} ({delivery.items.length}{" "}
                      {delivery.items.length > 1 ? "items" : "item"}):
                    </div>
                    {delivery.items.map((item, item_index) => {
                      return (
                        <div key={"item-" + item_index}>
                          <small>
                            {item.title}
                            {item.variant_1_value ||
                            item.variant_2_value ||
                            item.variant_3_value
                              ? "("
                              : ""}
                            {item.variant_1_value ? item.variant_1_value : ""}
                            {item.variant_2_value
                              ? "/" + item.variant_2_value
                              : ""}
                            {item.variant_3_value
                              ? "/" + item.variant_3_value
                              : ""}
                            {item.variant_1_value ||
                            item.variant_2_value ||
                            item.variant_3_value
                              ? ")"
                              : ""}{" "}
                            x {item.quantity}
                          </small>
                        </div>
                      );
                    })}
                    <div>
                      Delivered by: {delivery.option.provider} -{" "}
                      {delivery.option.service} ({delivery.option.speed}){" "}
                      <strong>
                        £{Number(delivery.option.cost).toFixed(2)}
                      </strong>
                    </div>
                  </div>
                );
              })}

              <button
                type="button"
                className="btn btn-sm btn-grey"
                onClick={() => updateDeliveryOption()}
              >
                Change
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
};

const mapStateToProps = createStructuredSelector({
  items: selectCartItems,
  customer: selectCurrentCustomer,
  shipping: selectCurrentShipping,
});

const mapDispatchToProps = (dispatch) => ({
  setCustomer: (customer) => dispatch(setCustomer(customer)),
  resetCustomer: () => dispatch(resetCustomer()),
  setShippingMethod: (method) => dispatch(setShippingMethod(method)),
  setDeliveryAddress: (address) => dispatch(setDeliveryAddress(address)),
  updateDeliveryAddress: () => dispatch(updateDeliveryAddress()),
  getShippableCountries: () => dispatch(getShippableCountries()),
  updateDeliveryOption: () => dispatch(updateDeliveryOption()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DeliveryAddress)
);

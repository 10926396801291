import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { Helmet } from 'react-helmet';

import { persistStore } from "redux-persist";

import CheckoutItem from "../components/cart/checkout-item.component";
//import StripeCheckoutButton from "../components/payments/stripe-button.component";

import {
  selectCartItems,
  selectCartTotal,
  selectCartVolume,
  selectCartItemMaxLength,
  selectCartItemMaxWidth,
  selectCartItemMaxHeight,
  history,
} from "../redux/cart/cart.selectors";

import { selectCurrentCustomer } from "../redux/customer/customer.selectors";

import { resetShipping } from "../redux/shipping/shipping.actions";
import { resetPayment } from "../redux/payment/payment.actions";

import "./checkout.styles.scss";

import $ from "jquery";

const CartPage = ({
  cartItems,
  total,
  volume,
  maxLength,
  maxWidth,
  maxHeight,
  customer,
  history,
  resetShipping,
  resetPayment,
}) => {
  $("#topnav").removeClass("open");
  resetShipping();
  resetPayment();

  if (cartItems.length > 0) {
    return (
      <main role="main" id="main">
        <Helmet>
        <title>Basket | Trinity House</title>
      </Helmet>
      <div className="basket">
        <div className="container p-5 bg-white">
          <div className="table-responsive-md">
            <table className="table table-hover table-basket">
              <thead>
                <tr>
                  <th className="text-center" width="300">
                    Image
                  </th>
                  <th className="text-center" width="35%">
                    Description
                  </th>
                  <th className="text-center" width="15%">
                    Unit Price
                  </th>
                  <th className="text-center" width="20%">
                    Quantity
                  </th>
                  <th className="text-center" width="15%">
                    Remove
                  </th>
                </tr>
              </thead>
              <tbody>
                {cartItems.map((cartItem) => (
                  <CheckoutItem key={cartItem.id} cartItem={cartItem} />
                ))}

                <tr className="no-hover">
                  <td
                    colSpan={2}
                    className="text-right vertical-middle no-border"
                  >
                    <h6>Total Basket Value: </h6>
                  </td>
                  <td className="text-center vertical-middle no-border">
                    <h6>£{total.toFixed(2)}</h6>
                  </td>
                  <td colSpan={2} className="no-border"></td>
                </tr>

                <tr className="no-hover">
                  <td colSpan={2} className="no-border"></td>
                  <td className="no-border">
                    <button
                      type="button"
                      className="btn btn-blue btn-block mt-2 text-uppercase"
                      onClick={() => history.push("/checkout")}
                    >
                      Checkout
                    </button>
                  </td>
                  <td colSpan={2} className="no-border"></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      </main>
    );
  } else {
    return (
      <div className="message" role="alert">
        The basket is empty.
      </div>
    );
  }
};

const mapStateToProps = createStructuredSelector({
  cartItems: selectCartItems,
  total: selectCartTotal,
  volume: selectCartVolume,
  maxLength: selectCartItemMaxLength,
  maxWidth: selectCartItemMaxWidth,
  maxHeight: selectCartItemMaxHeight,
  customer: selectCurrentCustomer,
});

const mapDispatchToProps = (dispatch) => ({
  resetShipping: () => dispatch(resetShipping()),
  resetPayment: () => dispatch(resetPayment()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CartPage)
);

import React, { Component } from "react";
import axios from "axios";

const WEB_API_URI = process.env.REACT_APP_WEB_API_URI;

class ShopFront extends Component {
  state = {
    title: "",
    image: "",
    feature: "",
    description: "",
  };

  componentDidMount = () => {
    let self = this;

    axios
      .get(WEB_API_URI)
      .then(function (response) {
        const shop = response.data.shop;

        self.setState({
          title: shop.title,
          image: shop.feature_image,
          feature: shop.feature,
          description: shop.description,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  render() {
    return (
      <div>
        <div className="page-header d-flex align-items-center">
          <div
            className="bgimage banner-image"
            style={{ backgroundImage: `url(${this.state.image})` }}
          >
            <p className="banner-caption asset-caption container text-right"></p>
          </div>
          <div className="page-header-content container text-white py-5">
            <div className="row">
              <div className="col-12 col-md-6">
                <h1 className="text-title mt-5 mt-lg-0">{this.state.title}</h1>
                <div className="my-3">
                  <div className="standfirst font-set-editor-regular">
                    {this.state.feature}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row my-5">
            <div className="col-12 col-md-8 main">
              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: this.state.description }}
              ></div>
              <p className="content">
                <a
                  className="cta"
                  href="https://trinityhouse.co.uk/shop/terms-and-conditions"
                >
                  Order, delivery and return information
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ShopFront;

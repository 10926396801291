import { createSelector } from 'reselect';

const selectShipping = state => state.shipping;

export const selectCurrentShipping = createSelector(
  [selectShipping],
  shipping => shipping
);

export const selectShippingCost = createSelector(
  [selectShipping],
  shipping => shipping.cost
);
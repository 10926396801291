import React from 'react';

import { OrderInfo } from '../components/shared/order-info.component';
import CategoryList from '../components/categories/category-list.component';
import ProductDetails from '../components/products/product-details.component';
import ProductRelated from '../components/products/product-related.component';
import { CovidNotice } from '../components/shared/covid-notice.component';

const ProductPage = (props) => {

  const productId = props.match.params.productId;

  return <main role="main" id="main">
      <div className="bg-light">
        <CategoryList />
      </div>
      {/* <CovidNotice /> */}
      <ProductDetails key={'product-' + productId} productId={productId} />
      <OrderInfo />
      {/* <ProductRelated key={'related-' + productId} productId={productId} /> */}
    </main>
}

export default ProductPage;
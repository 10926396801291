import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const API_URI = process.env.REACT_APP_API_URI;

class ProductRelated extends Component {
    constructor(props) {
        super(props);

        this.state = {
            productId: props.productId,
            products: []
        }
    }

    componentDidMount = () => {
        let self = this;

        const productId = self.state.productId

        axios.get(API_URI + '/products/' + productId+'/related')
            .then(function (response) {
                self.setState({ products: response.data.products });
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            });
    }

    render() {
        return (
            <section className="container">
                <h2 className="my-5">Related products</h2>
                    <ul id="products-list" className="products-list card-columns">
                        {this.state.products.map(product => (
                            <li key={'related-'+product.id} className="products-list-item card">
                                <Link className="products-list-link" to={ '/products/'+product.id }>
                                    <h3 className="text-standfirst">{ product.title }</h3>
                                    <img src={ product.image } alt={ product.title } />
                                    <div>
                                        <p className="description">{ product.feature }</p>
                                        <div className="from d-flex">From <div className="price d-flex ml-2">£{ product.price }</div></div>
                                    </div>
                                </Link>
                            </li>
                        ))}
                </ul>
            </section>
        );
    }
}

export default ProductRelated;
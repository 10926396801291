import $ from 'jquery';
import Dropdown from './dropdownNavLoader';

class NavLoader {
	constructor() {
		this.events();
	}

	events() {
		const $body = $('body'),
			  $topnav = $('#topnav'),
			  $mobileNav = $('#mobile-menu'),
        	  $mobileSearchButton = $('#mobile-search-button'),
        	  $mobileSearch = $('#mobile-search');

		Dropdown.new({
			selector: '#header'
		});

	    $mobileNav.on('click', function(e) {
			e.preventDefault();

	        $body.toggleClass('mobile-open');
	        $topnav.toggleClass('open');
	        $(this).toggleClass('open');

	        if ($mobileSearchButton.hasClass('open')) {
	            $mobileSearchButton.removeClass('open');
	            $mobileSearch.removeClass('open');
	        }
	    });

	    $mobileSearchButton.on('click', function() {
	        $body.toggleClass('mobile-open');
	        $mobileSearch.toggleClass('open');
	        $(this).toggleClass('open');

	        if ($mobileSearch.hasClass('open')) {
	            $mobileSearch.find('input[type=text]').focus();
	        }

	        if ($topnav.hasClass('open')) {
	            $topnav.removeClass('open');
	            $mobileNav.removeClass('open');
	        }
	    });
	}
}

export default {
	init() {
		new NavLoader();
	}
}
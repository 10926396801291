import React from "react";

import ProductVariant from "./product-variant.component";

const ProductVariants = ({ variants }) => {
  // convert to array
  let productVariantTypes = [];
  let variantValues = [];
  Object.keys(variants.types).map((key, index) => {
    productVariantTypes[index] = variants.types[key];
    variantValues[index] = `variant_${variants.types[key].variant_no}_value`;
  });

  const [variant, setVariant] = React.useState(false);
  const [filters, setFilters] = React.useState([]);

  const variant_1_values = variants.values.map((item) => item.variant_1_value);
  const variant_2_values = variants.values.map((item) => item.variant_2_value);
  const variant_3_values = variants.values.map((item) => item.variant_3_value);

  const arrayEquals = (array1, array2) => {
    return (
      Array.isArray(array1) &&
      Array.isArray(array2) &&
      array1.length === array2.length //&&
      //array1.every((val, index) => val === array2[index])
    );
  };

  React.useEffect(() => {
    //console.log(filters);

    const filterArray = Object.keys(filters);

    // console.log(filterArray);

    const doFilter = arrayEquals(filterArray, variantValues);

    // console.log(doFilter);

    if (doFilter) {
      const variant = variants.values.filter(function (item) {
        for (var key in filters) {
          if (item[key] === undefined || item[key] != filters[key])
            return false;
        }
        return true;
      });

      setVariant(variant[0]);
    }
  }, [filters]);

  //console.log(variants.values); // TODO
  //console.log(productVariantTypes); // TODO

  variants.values.map((item) => item);

  const setProductVariant = (event) => {
    //  console.log(event.target.value);

    setFilters({ ...filters, [event.target.name]: event.target.value });
  };

  //console.log(variant);

  return (
    <React.Fragment>
      {productVariantTypes.length > 0 ? <p>Please select ...</p> : ""}

      {productVariantTypes.map((variantType, index) => {
        return (
          <select
            onChange={(e) => setProductVariant(e)}
            key={index}
            name={`variant_${variantType.variant_no}_value`}
            className="form-select form-control mb-3"
            aria-label="select variant type"
          >
            <option value="">{variantType.name}</option>

            {variantType.options.split(",").map((option, index) => {
              console.log(variantType.variant_no);
              if (
                variantType.variant_no === 1 &&
                variant_1_values.includes(option.trim())
              ) {
                console.log(option);
                return (
                  <option key={index} value={option.trim()}>
                    {option}
                  </option>
                );
              } else if (
                variantType.variant_no === 2 &&
                variant_2_values.includes(option.trim())
              ) {
                return (
                  <option key={index} value={option.trim()}>
                    {option}
                  </option>
                );
              } else if (
                variantType.variant_no === 3 &&
                variant_3_values.includes(option.trim())
              ) {
                return (
                  <option key={index} value={option.trim()}>
                    {option}
                  </option>
                );
              }
            })}
          </select>
        );
      })}
      {variant ? <ProductVariant item={variant} /> : ""}
    </React.Fragment>
  );
};

export default ProductVariants;

import React from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { toggleCartHidden } from '../../redux/cart/cart.actions';

import { selectCartItemsCount } from '../../redux/cart/cart.selectors';

import { ReactComponent as ShoppingIcon } from '../../assets/shopping-bag.svg';

import './cart-icon.styles.scss';

const CartIcon = ({ toggleCartHidden, itemCount }) => {
  const history = useHistory();
  if(itemCount > 0) {
return (
<div className='cart-icon' onClick={() => history.push('/cart')}>
    <ShoppingIcon className='shopping-icon' />
    <span className='item-count'>{ itemCount } </span>
  </div>
);
  } else {
    return (
      <div className='cart-icon'>
          <ShoppingIcon className='shopping-icon' />
          <span className='item-count'>0</span>
        </div>
      );
  }
}
  

const mapStateToProps = state => ({
  itemCount: selectCartItemsCount(state)
});

const mapDispatchToProps = dispatch => ({
  toggleCartHidden: () => dispatch(toggleCartHidden())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CartIcon);

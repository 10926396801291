import React from 'react';
import { Helmet } from "react-helmet";
import { OrderInfo } from '../components/shared/order-info.component';
import UnlistedCategoryList from '../components/categories/unlisted-category-list.component';

const UnlistedCategoriesPage = () => {

  return <main role="main" id="main">
    <Helmet>
        <title>Categoires | Trinity House</title>
      </Helmet>
    <div className="bg-light">
      <UnlistedCategoryList />
    </div>
    <OrderInfo />
  </main>
}

export default UnlistedCategoriesPage;
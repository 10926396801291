import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { Formik, Form } from "formik";

import {
  getCollectionOptions,
  setCollectionOption,
} from "../../../redux/shipping/shipping.actions";

import { selectCurrentCustomer } from "../../../redux/customer/customer.selectors";
import { selectCurrentShipping } from "../../../redux/shipping/shipping.selectors";
import { selectCartItems } from "../../../redux/cart/cart.selectors";

const CollectionOptions = ({
  items,
  shipping,
  getCollectionOptions,
  setCollectionOption,
}) => {
  const [loadOptions, setLoadOptions] = React.useState(true);
  const [CollectionOptions, setCollectionOptions] = React.useState([]);

  React.useEffect(() => {
    if (shipping.set_collection_address && items.length > 0 && loadOptions) {
      const options = getCollectionOptions({
        collection_point_id: shipping.collection_address.id,
        items: items,
      });
      setCollectionOptions(options);
      setLoadOptions(false);
    }
  }, [shipping, items, loadOptions]);

  return shipping.method === "collection" &&
    shipping.set_collection_address &&
    shipping.set_collection_option === false &&
    shipping.collection_options.length > 0 ? (
    <div>
      <h6 className="my-3">Please choose a collection option:</h6>
      {shipping.collection_options.map((option, option_index) => {
        return (
          <div
            className="custom-control custom-radio"
            onClick={() => setCollectionOption(option)}
          >
            <input
              type="radio"
              id={"option-" + option_index}
              name="collection_option"
              className="custom-control-input"
              checked={false}
            />{" "}
            <label
              className="custom-control-label collection-option"
              htmlFor={"option-" + option_index}
            >
              {" "}
              {option.speed} <strong>£{Number(option.cost).toFixed(2)}</strong>
            </label>
          </div>
        );
      })}
    </div>
  ) : (
    ""
  );
};

const mapStateToProps = createStructuredSelector({
  items: selectCartItems,
  shipping: selectCurrentShipping,
});

const mapDispatchToProps = (dispatch) => ({
  getCollectionOptions: (data) => dispatch(getCollectionOptions(data)),
  setCollectionOption: (option) => dispatch(setCollectionOption(option)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CollectionOptions);


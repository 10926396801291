import PaymentActionTypes from "./payment.types";

const INITIAL_STATE = {
  method: "",
  set_billing_address: false,
  billing_same_as_shipping: false,
  billing_address: [],
};

const paymentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PaymentActionTypes.SET_PAYMENT_METHOD:
      if (action.payload === "card") {
        return {
          ...state,
          method: 'card',
          set_billing_address: false,
          billing_same_as_shipping: false,
        };
      } else if (action.payload === "paypal") {
        return {
          ...state,
          method: 'paypal',
          set_billing_address: false,
          billing_same_as_shipping: false,
        };
      }

    case PaymentActionTypes.SET_BILLING_ADDRESS:
      return {
        ...state,
        set_billing_address: true,
        billing_same_as_shipping: false,
        billing_address: action.payload,
      };

    case PaymentActionTypes.UPDATE_BILLING_ADDRESS:
      return {
        ...state,
        set_billing_address: false,
        billing_same_as_shipping: false,
      };

      case PaymentActionTypes.SET_BILLING_ADDRESS:
      return {
        ...state,
        billing_same_as_shipping:false,
        billing_address: action.payload,
      };
    
      case PaymentActionTypes.BILLING_SAME_AS_SHIPPING:
      return {
        ...state,
        set_billing_address: !state.billing_same_as_shipping,
        billing_same_as_shipping: !state.billing_same_as_shipping,
        billing_address: action.payload,
      };

    case PaymentActionTypes.RESET_PAYMENT:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default paymentReducer;

import { createSelector } from 'reselect';

const selectCart = state => state.cart;

export const selectCartItems = createSelector(
  [selectCart],
  cart => cart.cartItems
);

export const selectCartHidden = createSelector(
  [selectCart],
  cart => cart.hidden
);

export const selectCartItemsCount = createSelector(
  [selectCartItems],
  cartItems =>
    cartItems.reduce(
      (accumalatedQuantity, cartItem) =>
        accumalatedQuantity + cartItem.quantity,
      0
    )
);

export const selectCartTotal = createSelector(
  [selectCartItems],
  cartItems =>
    cartItems.reduce(
      (accumalatedQuantity, cartItem) =>
        accumalatedQuantity + cartItem.quantity * cartItem.price,
      0
    )
);

export const selectCartVolume = createSelector(
  [selectCartItems],
  cartItems =>
    cartItems.reduce(
      (accumalatedVolume, cartItem) =>
        accumalatedVolume + ((cartItem.length * cartItem.width * cartItem.height) * 2) * cartItem.quantity,
      0
    )
);

export const selectCartItemMaxLength = createSelector(
  [selectCartItems],
  cartItems => Math.max(...cartItems.map(item => Number(item.length)))
);

export const selectCartItemMaxWidth = createSelector(
  [selectCartItems],
  cartItems => Math.max(...cartItems.map(item => Number(item.width)))
);

export const selectCartItemMaxHeight = createSelector(
  [selectCartItems],
  cartItems => Math.max(...cartItems.map(item => Number(item.height)))
);

import React from "react";
import { Helmet } from "react-helmet";
import { OrderInfo } from "../components/shared/order-info.component";
import CategoryList from "../components/categories/category-list.component";
import ProductList from "../components/products/product-list.component";

const CategoryPage = (props) => {
  const categoryId = props.match.params.categoryId;

  return (
    <main role="main" id="main">
      <Helmet>
        <title>Categories | Trinity House</title>
      </Helmet>
      <div className="bg-light">
        <CategoryList />
      </div>
      <ProductList key={"category-" + categoryId} categoryId={categoryId} />
      <OrderInfo />
    </main>
  );
};

export default CategoryPage;

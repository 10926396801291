import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createStructuredSelector } from "reselect";

import DeliveryAddress from "./delivery/delivery-address.component";
import DeliveryOptions from "./delivery/delivery-options.component";

import CollectionAddress from "./collection/collection-address.component";
import CollectionOptions from "./collection/collection-options.component";

import {
  setShippingMethod,
  resetShipping,
  getShippableCountries,
} from "../../redux/shipping/shipping.actions";

import { resetPayment } from "../../redux/payment/payment.actions";

import { selectCurrentCustomer } from "../../redux/customer/customer.selectors";
import { selectCurrentShipping } from "../../redux/shipping/shipping.selectors";

const DeliveryMethod = ({
  customer,
  shipping,
  setShippingMethod,
  resetShipping,
  resetPayment,
}) => {
  
  const handleDeliveryMethod = (method) => {
    resetPayment();
    setShippingMethod(method);
  };

  return customer.set_customer ? (
    <div>
    <div className="bg-white mt-4 px-5 py-3">
      <h6 className="mb-3">DELIVERY METHOD</h6>

      <button
        type="button"
        className={`btn btn-blue ${
          shipping.method === "delivery" ? " active" : ""
        }`}
        onClick={() => handleDeliveryMethod('delivery')}
      >
        Delivery
      </button>
      {/* <button
        type="button"
        className={`btn btn-blue ml-3 ${
          shipping.method === "collection" ? " active" : ""
        }`}
        onClick={() => handleDeliveryMethod("collection")}
      >
        Collection
      </button> */}

      {/* <button
        type="button"
        className={`btn btn-outline-primary ml-3`}
        onClick={() => resetShipping()}
      >
        Reset
      </button> */}


    </div>

  {shipping.method !== "" ?
    <div className="bg-white mt-4 px-5 py-3">
        {shipping.method === "delivery" ? <DeliveryAddress /> : ""}
        {shipping.method === "collection" ? <CollectionAddress /> : ""}

        {shipping.set_delivery_address ? <DeliveryOptions /> : ""}
        {shipping.set_collection_address ? <CollectionOptions /> : ""}
      </div>
          : '' }
    </div>

  ) : '';
};

const mapStateToProps = createStructuredSelector({
  customer: selectCurrentCustomer,
  shipping: selectCurrentShipping,
});

const mapDispatchToProps = (dispatch) => ({
  setShippingMethod: (method) => dispatch(setShippingMethod(method)),
  resetShipping: () => dispatch(resetShipping()),
  getShippableCountries: () => dispatch(getShippableCountries),
  resetPayment: () => dispatch(resetPayment()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DeliveryMethod)
);

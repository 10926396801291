import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripePaymentForm from "./stripe-payment-form.component";
import BillingAddress from "./billing-address.component";

import { selectCurrentPayment } from "../../../redux/payment/payment.selectors";

const API_URI = process.env.REACT_APP_API_URI;
const STRIPE_PK = process.env.REACT_APP_STRIPE_PK;

const stripePromise = loadStripe(STRIPE_PK);

const StripePayment = ({ payment }) => {

  console.log(payment.set_billing_address);

  return (
    <Elements stripe={stripePromise}>
      <div className="mt-3 p-3">
        <BillingAddress />
        {payment.set_billing_address ? <StripePaymentForm /> : '' }
      </div>
    </Elements>
  );
}

const mapStateToProps = createStructuredSelector({
  payment: selectCurrentPayment,
});

export default connect(mapStateToProps, null)(StripePayment);

import React from "react";
import PaypalButton from "./paypal-button.component";

export default function PaypalPayment() {
  return (
    <div className="mt-3 p-3">
        <p className="mb-2">We'll redirect you to PayPal to complete your order when you confirm payment below.</p>
      <PaypalButton />
    </div>
  );
}

import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import axios from "axios";
import ProductVariants from "./product-variants.component";
import ProductSlideshow from "./product-slideshow.component";
import PageInfo from "../shared/page-info.component";

import { connect } from "react-redux";

import { addItem } from "../../redux/cart/cart.actions";

import GotoCartButton from "../cart/goto-cart-button.component";

const API_URI = process.env.REACT_APP_API_URI;

const ProductDetails = ({ productId }) => {
  const history = useHistory();
  const [product, setProduct] = React.useState(false);
  const [loadProductDetails, setLoadProductDetails] = React.useState(true);

  React.useEffect(() => {
    if (loadProductDetails) {
      console.log("loading product deatils ...");

      axios
        .get(API_URI + "/products/" + productId + "/published")
        .then(function (response) {
          setProduct(response.data.data);
          setLoadProductDetails(false);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          setLoadProductDetails(false);
        });
    }
  }, [loadProductDetails]);

  // if all categories are not published
  if (product !== false) {
    product.categories.map((category) => {
      if (category.status === "draft") {
        history.push("/");
      }
    });
  }

  return product !== false ? (
    <article role="main">
      <Helmet>
        <title>{product.title} | Trinity House</title>
      </Helmet>

      <div className="container">
        <div className="row">
          <PageInfo
            title={product.title}
            feature={product.standfirst}
            showSubscribe={false}
          />
        </div>
      </div>

      <div className="container mb-5">
        <div className="row">
          <div className="col-12 col-md-6">
            <ProductSlideshow images={product.images} />
          </div>
          <div className="col-12 col-md-6">
            <div
              className="content product-description"
              dangerouslySetInnerHTML={{ __html: product.description }}
            ></div>
            <div className="shop-prices mt-3 mb-5">
              <ProductVariants variants={product.variants} />
            </div>
            <GotoCartButton />
          </div>
        </div>
      </div>
    </article>
  ) : (
    <article role="main">
      <div className="container">
        <div className="row">Loading ...</div>
      </div>
    </article>
  );
};

const mapDispactToProps = (dispatch) => ({
  addItem: (item) => addItem(dispatch(item)),
});

export default connect(null, mapDispactToProps)(ProductDetails);

import React from 'react';

import $ from 'jquery';

import ShopFront from '../components/shared/shopfront.component';
import { OrderInfo } from '../components/shared/order-info.component';
import Categories from '../components/categories/category-list.component';
import FeaturedProducts from '../components/products/featured-products.component';

const HomePage = () => {

  $('#topnav').removeClass('open');

  return <main role="main" id="main">
    <ShopFront />
    <FeaturedProducts />
    <Categories />
    <OrderInfo />
  </main>
}

export default HomePage;
import React from "react";
import { connect } from "react-redux";
import { addItem } from "../../redux/cart/cart.actions";

const ProductVariant = ({ item, addItem }) => {
  console.log(item);

  return (
    <div className="mb-3">
      <div className="product-price">£{item.price}</div>

      {item.stock > 0 ? (<button
        type="button"
        className="btn btn-blue ml-2"
        aria-label="Add to Basket"
        onClick={() => addItem(item)}
      >
        Add to Basket
      </button>) : 'Out of Stock'}
      
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  addItem: (item) => dispatch(addItem(item)),
});

export default connect(null, mapDispatchToProps)(ProductVariant);

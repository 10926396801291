import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const API_URI = process.env.REACT_APP_API_URI;

class FeaturedProducts extends Component {

    state = {
        products: []
    }


    componentDidMount = () => {

        let self = this;

        axios.get(API_URI + '/featured-products')
            .then(function (response) {
                self.setState({ products: response.data.data });
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            });
    }


    render() {

        console.log(this.state.products);


        return (
            <div className="inner bg-light">
                <div className="container py-5 ">

                    <div className="row">
                        <div className="col">
                            <h2 className="text-standfirst mb-2">Featured products</h2>
                        </div>
                    </div>

                    <div id="products-banner-slideshow" className="products-banner-slideshow carousel slide" data-ride="carousel">
                        <div className="carousel-inner">
                            {this.state.products.map((product, productIndex) => (
                                <div key={'slide-' + productIndex} className={'carousel-item' + ((productIndex === 0) ? ' active' : '')}>
                                    <Link className="row" to={'/products/' + product.id}>
                                        <div className="col-12 col-md-6">
                                            <img className="mh-100 border main-slide-item" src={product.image_location} alt={product.image_description} />
                                        </div>
                                        <div className="col">
                                            <h3 className="slide-title">{product.title}</h3>
                                            <p className="my-4 slide-caption">{ product.standfirst } </p>
                                            <span className="btn btn-outline-primary">Buy {product.title}</span>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </div>

                        <ol className="carousel-indicators">
                            {this.state.products.map((product, index) => (
                                <li key={'slide-control-' + index} data-target="#products-banner-slideshow" data-slide-to={index} className={(index === 0) ? 'active' : ''}></li>
                            ))}
                        </ol>
                    </div>
                </div>
            </div>
        )
    }
}


export default FeaturedProducts;


import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { Formik, Form } from "formik";

import {
  setCustomer,
  changeCustomer,
  resetCustomer,
} from "../../redux/customer/customer.actions";
import { selectCurrentCustomer } from "../../redux/customer/customer.selectors";

import * as Yup from "yup";

const CustomerSchema = Yup.object().shape({
  name: Yup.string().required("Name is required."),
  email: Yup.string()
    .email("Invalid Email address.")
    .required("Email Address is required."),
});

const Customer = ({ customer, setCustomer, changeCustomer, resetCustomer }) => {
  const initialValues = {
    name: customer.details.name !== undefined ? customer.details.name : "",
    email: customer.details.email !== undefined ? customer.details.email : "",
  };

  return (
    <div className="bg-white px-5 py-3">
      <h6 className="mb-3">YOUR DETAILS</h6>
      {customer.set_customer ? (
        <div>
          {customer.details.name} ({customer.details.email})
          <button
            type="button"
            className="ml-3 btn btn-grey"
            onClick={() => changeCustomer()}
          >
            Change
          </button>
        </div>
      ) : (
        <div>
          <Formik
            initialValues={initialValues}
            validationSchema={CustomerSchema}
            onSubmit={(values, actions) => {
              setCustomer(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <Form noValidate autoComplete="off">
                <div className="form-group row">
                  <label htmlFor="name" className="col-sm-3 col-form-label">
                    Name <span className="text-danger">*</span>
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      id="name"
                      placeholder="Name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.name && touched.name ? (
                      <small className="text-danger">{errors.name}</small>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="email" className="col-sm-3 col-form-label">
                    Email Address <span className="text-danger">*</span>
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      name="email"
                      className="form-control"
                      id="email"
                      placeholder="Email Address"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.email && touched.email ? (
                      <small className="text-danger">{errors.email}</small>
                    ) : null}
                  </div>
                </div>

                <div className="form-group row">
                  <div className="offset-sm-3 col-sm-9">
                    {customer.details.email !== undefined ? (<div>
                        <button type="submit" className="btn btn-blue">
                          Update
                        </button>
                        <button
                          type="submit"
                          className="btn btn-grey ml-3"
                          onClick={() => setCustomer(initialValues)}
                        >
                          Cancel
                        </button>
                      </div>) : (
                      <button type="submit" className="btn btn-blue">
                        Continue to choose Delivery
                      </button>
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  customer: selectCurrentCustomer,
});

const mapDispatchToProps = (dispatch) => ({
  setCustomer: (customer) => dispatch(setCustomer(customer)),
  changeCustomer: () => dispatch(changeCustomer()),
  resetCustomer: () => dispatch(resetCustomer()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Customer)
);

import React from 'react';
import LCPDetails from '../components/products/lcp-details.component';

const LCPItemPage = (props) => {

  const productUrl = props.match.params.productUrl;

  return <main role="main" id="main">
      <LCPDetails key={'lcp-item'} productUrl={productUrl} />
    </main>
}

export default LCPItemPage;
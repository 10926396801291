const ShippingActionTypes = {
  SET_SHIPPING_METHOD: 'SET_SHIPPING_METHOD',
  GET_SHIPPABLE_COUNTRIES: 'GET_SHIPPABLE_COUNTRIES',

  SET_DELIVERY_ADDRESS: 'SET_DELIVERY_ADDRESS',
  UPDATE_DELIVERY_ADDRESS: 'UPDATE_DELIVERY_ADDRESS',
  GET_DELIVERY_OPTIONS: 'GET_DELIVERY_OPTIONS',
  SET_DELIVERIES: 'SET_DELIVERIES',
  UPDATE_DELIVERY_OPTION: 'UPDATE_DELIVERY_OPTION',

  GET_AVAILABLE_COLLECTION_ADDRESSES: 'GET_AVAILABLE_COLLECTION_ADDRESSES',
  SET_COLLECTION_ADDRESS: 'SET_COLLECTION_ADDRESS',
  UPDATE_COLLECTION_ADDRESS: 'UPDATE_COLLECTION_ADDRESS',
  GET_COLLECTION_OPTIONS: 'GET_COLLECTION_OPTIONS',
  SET_COLLECTION_OPTION: 'SET_COLLECTION_OPTION',
  UPDATE_COLLECTION_OPTION: 'UPDATE_COLLECTION_OPTION',

  RESET_SHIPPING: 'RESET_SHIPPING'
};

export default ShippingActionTypes;

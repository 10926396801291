import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { Helmet } from 'react-helmet';

import {
  resetCustomer,
} from "../redux/customer/customer.actions";
import { selectCurrentCustomer } from "../redux/customer/customer.selectors";

import MiniCart from "../components/cart/mini-cart.component";
import Customer from "../components/customer/customer.comopnent";
import DeliveryMethod from '../components/shipping/delivery-method.component';
import PaymentMethod from '../components/payments/payment-method.component';
import { selectCurrentShipping } from "../redux/shipping/shipping.selectors";

const Checkout = ({ customer, shipping, resetCustomer}) => {

  return (
    <main role="main" id="main">
      <Helmet>
        <title>Checkout | Trinity House</title>
      </Helmet>
    <div className="basket">
    <div className="container py-4">
      <div className="row">
        <div className="col-md-8">
            <Customer /> 
            <DeliveryMethod />
            {shipping.set_deliveries || shipping.set_collection_option ? <PaymentMethod />  : ''}
        </div>
        <div className="col-md-4">
          { <MiniCart /> }
        </div>
      </div>
    </div>
    </div>
    </main>
  );
};

const mapStateToProps = createStructuredSelector({
    customer: selectCurrentCustomer,
    shipping: selectCurrentShipping,

  });
  
  const mapDispatchToProps = (dispatch) => ({
    resetCustomer: () => dispatch(resetCustomer()),
  });
  
  export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Checkout)
  );
  



import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { Formik, Form } from "formik";

import { countries } from "../../../config/countries";

import {
  setCustomer,
  resetCustomer,
} from "../../../redux/customer/customer.actions";

import {
  setShippingMethod,
  setDeliveryAddress,
  updateDeliveryAddress,
  resetShippingMethod,
  getAvailableCollectionAddresses,
  setCollectionAddress,
  updateCollectionAddress,
  updateCollectionOption,
} from "../../../redux/shipping/shipping.actions";

import { selectCurrentCustomer } from "../../../redux/customer/customer.selectors";
import { selectCurrentShipping } from "../../../redux/shipping/shipping.selectors";
import { selectCartItems } from "../../../redux/cart/cart.selectors";

const CollectionAddress = ({
  shipping,
  items,
  getAvailableCollectionAddresses,
  setCollectionAddress,
  updateCollectionAddress,
  updateCollectionOption,
}) => {
  const [loadAddresses, setLoadAddresses] = React.useState(true);

  React.useEffect(() => {
    if (shipping.method === "collection" && items.length > 0 && loadAddresses) {
      getAvailableCollectionAddresses();
      setLoadAddresses(false);
    }
  }, [shipping, items, loadAddresses]);

  if (shipping.set_collection_address) {
    return (
      <div className="row">
        <div className="col-sm-12 col-md-6">
          <h6 className="mt-3">Collect from:</h6>
          <div>{shipping.collection_address.address_line_1}</div>
          <div>{shipping.collection_address.address_line_2}</div>
          <div>{shipping.collection_address.city}</div>
          <div>{shipping.collection_address.county}</div>
          <div>{shipping.collection_address.postcode}</div>
          <div>{shipping.collection_address.country_code !== undefined ? countries.find(country => country && country.code === shipping.collection_address.country_code).label : ''}</div>

          <button
            type="button"
            className="btn btn-sm btn-grey"
            onClick={() => updateCollectionAddress()}
          >
            Change
          </button>

          <div><strong>Thank you for selecting the collection point. Please choose the collection option to get to the payment option.</strong></div>

        </div>

        {shipping.set_collection_option ? (
          <div className="col-md-6 col-sm-12">
            <h6>Collection Option:</h6>

            <div>
              {shipping.collection_option.speed}{" "}
              <strong>
                £{Number(shipping.collection_option.cost).toFixed(2)}
              </strong>
            </div>

            <button
              type="button"
              className="btn btn-sm btn-grey"
              onClick={() => updateCollectionOption()}
            >
              Change
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  } else if (
    shipping.set_collection_address === false &&
    shipping.available_collection_addresses.length > 0
  ) {
    return (
      <div>
        <h6 className="my-3">COLLECTION</h6>
        <h6 className="my-3">Please choose your collection point:</h6>
        <div className="row">
          {shipping.available_collection_addresses.map((address, index) => {
            return (
              <div key={index} className="col-sm-12 col-md-6 mb-4">
                <div
                  className="text-left collection-address"
                  onClick={() => setCollectionAddress(address)}
                >
                  <div><strong>{address.address_line_1}</strong></div>
                  <div>{address.address_line_2}</div>
                  <div>{address.city}</div>
                  <div>{address.county}</div>
                  <div>{address.postcode}</div>
                  <div>{address.country_code !== undefined ? countries.find(country => country && country.code === address.country_code).label : ''}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
};

const mapStateToProps = createStructuredSelector({
  customer: selectCurrentCustomer,
  shipping: selectCurrentShipping,
  items: selectCartItems,
});

const mapDispatchToProps = (dispatch) => ({
  getAvailableCollectionAddresses: () =>
    dispatch(getAvailableCollectionAddresses()),
  setCollectionAddress: (address) => dispatch(setCollectionAddress(address)),
  updateCollectionAddress: () => dispatch(updateCollectionAddress()),
  updateCollectionOption: () => dispatch(updateCollectionOption()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CollectionAddress)
);

import React from "react";
import { useSnackbar } from "notistack";
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { Formik, Form } from "formik";

import axios from "axios";

import {
  setBillingAddress,
  updateBillingAddress,
  resetPayment,
} from "../../../redux/payment/payment.actions";
import { selectCurrentPayment } from "../../../redux/payment/payment.selectors";

import { resetCustomer } from '../../../redux/customer/customer.actions';
import { selectCurrentCustomer } from "../../../redux/customer/customer.selectors";

import { resetCart } from '../../../redux/cart/cart.actions';
import {
  selectCartItems,
  selectCartTotal,
} from "../../../redux/cart/cart.selectors";

import { resetShipping } from '../../../redux/shipping/shipping.actions';
import { selectCurrentShipping } from "../../../redux/shipping/shipping.selectors";

import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

const API_URI = process.env.REACT_APP_API_URI;
const PAYPAL_CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID;

const options = {
  "client-id": PAYPAL_CLIENT_ID,
  currency: "GBP",
};

const PaypalButton = ({ customer, items, cartTotal, shipping, payment, resetCart, resetCustomer, resetShipping, resetPayment }) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  // now create order details
  const totalAmountToPay = Number(cartTotal) + Number(shipping.cost);

  const customerDetails = {
    name: customer.details.name,
    email: customer.details.email,
  };

  let shippingDetails = {};
  if (shipping.method === "delivery") {
    shippingDetails = {
      method: "delivery",
      delivery_address: shipping.delivery_address,
      deliveries: shipping.deliveries,
      cost: shipping.cost,
    };
  } else if (shipping.method === "collection") {
    shippingDetails = {
      method: "collection",
      collection_address: shipping.collection_address,
      collection_option: shipping.collection_option,
      cost: shipping.cost,
    };
  }

  return (
    <PayPalScriptProvider options={options}>
      <PayPalButtons
        style={{ layout: "horizontal" }}
        createOrder={(data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: totalAmountToPay.toFixed(2),
                },
              },
            ],
            application_context: { shipping_preference: "NO_SHIPPING" },
          });
        }}
        onApprove={async (data, actions) => {
          const capture = await actions.order.capture();

          if (capture !== undefined && capture.status === "COMPLETED") {
            const billing_address = {
              name:
                capture.payer.name.given_name +
                " " +
                capture.payer.name.surname,
              address_line_1: capture.payer.address.address_line_1 ?? "",
              address_line_2: capture.payer.address.admin_area_1 ?? "",
              city: capture.payer.address.admin_area_2 ?? "",
              county: "",
              postcode: capture.payer.address.postal_code ?? "",
              country: capture.payer.address.country_code,
              email: capture.payer.email_address,
            };

            const paymentDetails = {
              type: "paypal",
              billing: billing_address,
              amount: totalAmountToPay,
              payment_id: capture.purchase_units[0].payments.captures[0].id,
              payment_status:
                capture.purchase_units[0].payments.captures[0].status,
            };

            const orderDetails = {
              customer: customerDetails,
              basket_items: items,
              shipping: shippingDetails,
              payment: paymentDetails,
            };

            console.log(orderDetails);

            axios.post(API_URI + "/orders", orderDetails)
              .then((response) => {

                if (response.data.status === "success") {
                  enqueueSnackbar(`Your order has been placed successfully.`, {
                    variant: "success",
                    persist: false,
                  });

                  resetCart();
                  resetCustomer();
                  resetShipping();
                  resetPayment();

                  history.push("/order-complete");
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        }}
      />
    </PayPalScriptProvider>
  );
};

const mapStateToProps = createStructuredSelector({
  customer: selectCurrentCustomer,
  items: selectCartItems,
  cartTotal: selectCartTotal,
  shipping: selectCurrentShipping,
  payment: selectCurrentPayment,
});

const mapDispatchToProps = (dispatch) => ({
  setBillingAddress: (address) => dispatch(setBillingAddress(address)),
  updateBillingAddress: () => dispatch(updateBillingAddress()),
  resetCart: () => dispatch(resetCart()),
  resetCustomer: () => dispatch(resetCustomer()),
  resetShipping: () => dispatch(resetShipping()),
  resetPayment: () => dispatch(resetPayment()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PaypalButton)
);

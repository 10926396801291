import $ from 'jquery';
import 'bootstrap/js/dist/carousel';

class Carousel {
	constructor() {
		this.events();
	}

	events() {
	  //  const $carousel = $('.section-fullview-slideshow');

	    $('.section-fullview-slideshow').on('slid.bs.carousel', function () {
	    	const $caption = $('.section-fullview-caption'),
	    		  $activeSlide = $('.carousel-indicator-item.active').data('slide-to');

	    	$caption.each(function () {
	    		if ($(this).data('caption-to') === $activeSlide) {
	    			$('.section-fullview-caption.active').removeClass('active');
	    			$(this).addClass('active');
	    		}
	    	});
	    });
	}
}

export default {
	init () {
		new Carousel();
	}
}
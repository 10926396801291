import React from "react";
import { connect } from "react-redux";

import { selectCartItemsCount } from "../../redux/cart/cart.selectors";

import { withRouter } from "react-router-dom";

import "./cart-icon.styles.scss";

const CheckoutButton = ({ itemCount, history }) => {
  if (itemCount > 0) {
    return (
      <div>
        <p>
          <strong>You have {itemCount} item(s) in the Basket</strong>
        </p>
        <div
          className="checkout-icon btn btn-primary mb-5"
          aria-label="Go to Checkout"
          onClick={() => {
            history.push("/checkout");
          }}
        >
          <span>GO TO CHECKOUT</span>
        </div>
      </div>
    );
  } else {
    return (
      <div className="mb-5">
        <p>
          <strong>You have {itemCount} item(s) in the Basket</strong>
        </p>
      </div>
    );
  }
};

const mapStateToProps = (state) => ({
  itemCount: selectCartItemsCount(state),
});

export default withRouter(connect(mapStateToProps)(CheckoutButton));

import React from "react";

const PageInfo = ({ title, feature, showSubscribe = true }) => (
  <div className="container pt-5 pb-4">
    <div className="row">
      <div className="col-12 col-md-8 main">
        <h1 id="b-page-title" className="text-title">{title}</h1>
        <div className="mb-3 mt-2">
          <div
              className="content"
              dangerouslySetInnerHTML={{ __html: feature }}
            ></div>
        </div>
      </div>

{ showSubscribe ? 
      <div className="col-12 col-md-4">
        <a
          href="https://www.trinityhouse.co.uk/subscribe?list=shop"
          className="card p-4 bg-primary text-white rounded shop-signup"
          style={{ minHeight: "auto" }}
        >
          <h3>Keep in touch</h3>
          <p className="my-3">
            For new product updates subscribe to our mailing list
          </p>
          <span className="btn btn-light">Subscribe</span>
        </a>
      </div>
      : '' }
    </div>
  </div>
);

export default PageInfo;

import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import CartIcon from "../../components/cart/cart-icon.component";
import CartIconMobile from "../../components/cart/cart-icon-mobile.component";
import CartDropdown from "../cart/mini-cart.component";

import NavLoader from "../../js/navLoader";

const WEB_API_URI = process.env.REACT_APP_WEB_API_URI;

class Header extends Component {
  state = {
    nav_items: [],
  };

  componentDidMount = () => {
    let self = this;

    axios
      .get(WEB_API_URI + "/nav-items")
      .then(function (response) {
        self.setState({ nav_items: response.data.nav_items });
      })
      .catch(function (error) {
        console.log(error);
      });

    NavLoader.init();
  };

  render() {
    return (
      <div>
        <a href="#main" className="skip-to-main-content-link">
          Skip to main content
        </a>
        <header className="header" id="header">
          <a
            id="logo"
            className="header-logo float-left"
            href="https://trinityhouse.co.uk"
          >
            <img
              className="mh-100 d-block m-md-auto p-1 p-md-3"
              src="/images/logo.png"
              alt="Trinity House Logo"
            />
            <span className="sr-only">Home</span>
          </a>
          <img
            id="print-logo"
            src="/images/logo.png"
            alt="Trinity House Logo"
          />
          <Link to="/cart" className="mobile-search-button">
            <CartIconMobile />
          </Link>

          <div
            id="mobile-menu"
            href="/"
            className="mobile-menu fa fa-bars"
          ></div>

          <div
            id="topnav"
            className="header-topnav"
            role="region"
            aria-label="Nav"
          >
            <nav>
              <ul
                role="region"
                aria-label="Top Nav"
                id="secondary-nav"
                className="header-secondary-nav p-0"
              >
                <li>
                  <a
                    className="header-secondary-nav-link"
                    href="https://trinityhouse.co.uk/about-us"
                  >
                    About us
                  </a>
                </li>
                <li>
                  <a
                    className="header-secondary-nav-link"
                    href="https://trinityhouse.co.uk/media"
                  >
                    Media
                  </a>
                </li>
                <li>
                  <a
                    className="header-secondary-nav-link"
                    href="https://trinityhouse.co.uk/about-us/work-for-trinity-house/vacancies"
                  >
                    Vacancies
                  </a>
                </li>
                <li>
                  <Link className="header-secondary-nav-link" to="/">
                    Shop
                  </Link>
                </li>
                <li>
                  <a
                    className="header-secondary-nav-link"
                    href="https://trinityhouse.co.uk/about-us/contact-us"
                  >
                    Contact
                  </a>
                </li>
                <li>
                  <a
                    className="header-secondary-nav-link"
                    href="https://dashboard.trinityhouse.co.uk"
                  >
                    Members' area
                  </a>
                </li>
                <li>
                  <CartIcon />
                </li>
              </ul>

              <ul className="header-main-nav">
                {this.state.nav_items.map((item) => (
                  <li key={item.id}>
                    <a
                      aria-haspopup="true"
                      aria-expanded="false"
                      aria-controls={"dd-" + item.id}
                      className="dropdown"
                      href={item.url}
                    >
                      {item.title}
                    </a>
                    <a href="/" className="fa fa-plus expand">
                      <span>Expand menu</span>
                    </a>
                    <div className="dropdown" id={"dd-" + item.id}>
                      <ul>
                        {item.pages.map((page, index) => (
                          <li key={index}>
                            <a href={page.url}>
                              {page.image ? (
                                <span
                                  className="image"
                                  style={{
                                    backgroundImage: `url(${page.image})`,
                                  }}
                                ></span>
                              ) : (
                                ""
                              )}
                              <p>{page.title}</p>
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
          <div id="dropdown"></div>
        </header>
      </div>
    );
  }
}

const mapStateToProps = ({ cart: { hidden } }) => ({
  hidden,
});

export default connect(mapStateToProps)(Header);

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { Formik, Form } from "formik";

import {
  setCustomer,
  resetCustomer,
} from "../../../redux/customer/customer.actions";

import {
  getDeliveryOptions,
  setDeliveries,
} from "../../../redux/shipping/shipping.actions";

import { resetPayment } from "../../../redux/payment/payment.actions";

import { selectCurrentCustomer } from "../../../redux/customer/customer.selectors";
import { selectCurrentShipping } from "../../../redux/shipping/shipping.selectors";
import { selectCartItems } from "../../../redux/cart/cart.selectors";

const DeliveryOptions = ({
  items,
  shipping,
  getDeliveryOptions,
  setDeliveries,
  resetPayment,
}) => {
  const [loadOptions, setLoadOptions] = React.useState(true);
  const [deliveryOptions, setDeliveryOptions] = React.useState([]);

  resetPayment();

  React.useEffect(() => {
    if (shipping.set_delivery_address && items.length > 0 && loadOptions) {
      const options = getDeliveryOptions({
        address: shipping.delivery_address,
        items: items,
      });
      setDeliveryOptions(options);
      setLoadOptions(false);
    }
  }, [shipping, items, loadOptions]);

  //&& shipping.set_delivery_option === false

  return shipping.method === "delivery" &&
    shipping.set_delivery_address &&
    shipping.set_deliveries === false ? (
    <div>
      <h6 className="mb-2">
        Your items will be delivered in {shipping.delivery_options.length}{" "}
        packages.
      </h6>
      {shipping.delivery_options.map((delivery, delivery_index) => {
        return (
          <div key={delivery_index} className="delivery-options">
            <h6 className="my-0">Package {delivery_index + 1} items:</h6>
            {delivery.items.map((item, item_index) => {
              return (
                <div key={"item-" + item_index}>
                  <small>
                    {item.title}
                    {item.variant_1_value ||
                    item.variant_2_value ||
                    item.variant_3_value
                      ? "("
                      : ""}
                    {item.variant_1_value ? item.variant_1_value : ""}
                    {item.variant_2_value ? "/" + item.variant_2_value : ""}
                    {item.variant_3_value ? "/" + item.variant_3_value : ""}
                    {item.variant_1_value ||
                    item.variant_2_value ||
                    item.variant_3_value
                      ? ")"
                      : ""}{" "}
                    x {item.quantity}
                  </small>
                </div>
              );
            })}

            {delivery.options.length > 0 ? (
              <div>
                <h6 className="my-1">Please choose a delivery option:</h6>
                {delivery.options.map((option, option_index) => {
                  let option_id =
                    "delivery_" + delivery_index + "_option_" + option_index;
                  const delivery_option = shipping.deliveries.find(
                    (delivery) => delivery.id === option_id
                  );
                  return (
                    <div key={"option-" + option_index}>
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          id={option_id}
                          name={"delivery-" + delivery_index + "-opton"}
                          className="custom-control-input"
                          //checked={false}
                          onClick={() =>
                            setDeliveries({
                              id: option_id,
                              delivery: delivery_index,
                              items: delivery.items,
                              option: option,
                            })
                          }
                        />{" "}
                        <label
                          className="custom-control-label delivery-option"
                          htmlFor={option_id}
                        >
                          {" "}
                          {option.provider} - {option.service} ({option.speed}){" "}
                          <strong>£{Number(option.cost).toFixed(2)}</strong>
                        </label>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div>
                <small className="text-danger">{delivery.message}</small>
              </div>
            )}
          </div>
        );
      })}
    </div>
  ) : (
    ""
  );
};

const mapStateToProps = createStructuredSelector({
  items: selectCartItems,
  shipping: selectCurrentShipping,
});

const mapDispatchToProps = (dispatch) => ({
  getDeliveryOptions: (data) => dispatch(getDeliveryOptions(data)),
  setDeliveries: (delivery) => dispatch(setDeliveries(delivery)),
  resetPayment: () => dispatch(resetPayment()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryOptions);
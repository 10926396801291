import React from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';

import {
  clearItemFromCart,
  addItem,
  removeItem,
} from "../../redux/cart/cart.actions";

import "./checkout-item.styles.scss";

const CheckoutItem = ({ cartItem, clearItem, addItem, removeItem }) => {

  const {
    product_id,
    title,
    image,
    price,
    quantity,
    variant_1_name,
    variant_1_value,
    variant_2_name,
    variant_2_value,
    variant_3_name,
    variant_3_value,
  } = cartItem;
  return (
    <tr>
      <td>
        <div className="image-container text-center">
          {image === null ? (
            <img
              className="cart-image"
              src="/images/product-placeholder-thumbnail.png"
              alt={title}
            />
          ) : (
            <img className="cart-image" src={image} alt={title} />
          )}
        </div>
      </td>
      <td className="vertical-middle">
        <Link to={`/products/${product_id}`}>{title}
        <br />
        <small>
          {variant_1_value !== null
            ? `(${variant_1_name}:${variant_1_value})`
            : ""}
        </small>
        <small>
          {variant_2_value !== null
            ? `(${variant_2_name}:${variant_2_value})`
            : ""}
        </small>
        <small>
          {variant_3_value !== null
            ? `(${variant_3_name}:${variant_3_value})`
            : ""}
        </small>
        </Link>
      </td>
      <td className="text-center vertical-middle">£{price}</td>
      <td className="text-center vertical-middle">
        <div className="quantity">
          <div
            className="arrow float-left" aria-label="Decrease quantity" 
            onClick={() => removeItem(cartItem)}
          >
            &#10094;
          </div>
          <span className="value">{quantity}</span>
          <div className="arrow float-right" aria-label="Increase quantity" onClick={() => addItem(cartItem)}>
            &#10095;
          </div>
        </div>
      </td>

      <td className="text-center vertical-middle">
        {" "}
        <div className="remove-button" aria-label="Remove item" onClick={() => clearItem(cartItem)}>
          &#10005;
        </div>
      </td>
    </tr>
  );
};

const mapDispatchToProps = (dispatch) => ({
  clearItem: (item) => dispatch(clearItemFromCart(item)),
  addItem: (item) => dispatch(addItem(item)),
  removeItem: (item) => dispatch(removeItem(item)),
});

export default connect(null, mapDispatchToProps)(CheckoutItem);

import ShippingActionTypes from "./shipping.types";

const INITIAL_STATE = {
  method: "",
  shippable_countries: [],

  set_delivery_address: false,
  delivery_address: [],
  delivery_options: [],
  set_deliveries: false,
  deliveries: [],

  available_collection_addresses: [],

  set_collection_address: false,
  collection_address: [],
  collection_options: [],
  set_collection_option: false,
  collection_option: [],

  cost: 0,
};

const shippingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ShippingActionTypes.SET_SHIPPING_METHOD:
      if (action.payload === "collection") {
        return {
          ...state,
          method: action.payload,
          delivery_options: [],
          set_delivery_address: false,
          set_deliveries: false,
          deliveries: [],
          cost: 0,
        };
      } else if (action.payload === "delivery") {
        return {
          ...state,
          method: action.payload,
          set_collection_address: false,
          set_collection_option: false,
          collection_option: [],
          cost: 0,
        };
      }

    case ShippingActionTypes.GET_SHIPPABLE_COUNTRIES:
      return {
        ...state,
        shippable_countries: action.payload,
      };
    case ShippingActionTypes.SET_DELIVERY_ADDRESS:
      return {
        ...state,
        set_delivery_address: true,
        delivery_address: action.payload.address,
      };
    case ShippingActionTypes.UPDATE_DELIVERY_ADDRESS:
      return {
        ...state,
        set_delivery_address: false,
        set_deliveries: false,
        deliveries: [],
        cost: 0,
      };

    case ShippingActionTypes.GET_DELIVERY_OPTIONS:
      return {
        ...state,
        delivery_options: action.payload, //getDeliveryOptions(action.payload)
      };

    case ShippingActionTypes.SET_DELIVERIES:
      console.log(state.deliveries);

      const deliveries = state.deliveries;
      const has_delivery = deliveries.find(
        (delivery_package) =>
          delivery_package.delivery === action.payload.delivery
      );

      let updated_deliveries = [];
      if (has_delivery) {
        updated_deliveries = deliveries.map((delivery_package) =>
          delivery_package.delivery === action.payload.delivery
            ? action.payload
            : delivery_package
        );
      } else {
        deliveries.push(action.payload);
        updated_deliveries = deliveries.map(
          (delivery_package) => delivery_package
        );
      }

      let total_cost = 0;
      updated_deliveries.map((delivery) => {
        total_cost += Number(delivery.option.cost);
      });

      return {
        ...state,
        set_deliveries:
          updated_deliveries.length > 0 &&
          updated_deliveries.length === state.delivery_options.length
            ? true
            : false,
        deliveries: updated_deliveries,
        cost: total_cost,
      };

    case ShippingActionTypes.UPDATE_DELIVERY_OPTION:
      return {
        ...state,
        set_deliveries: false,
        deliveries: [],
        cost: 0,
      };

    case ShippingActionTypes.GET_AVAILABLE_COLLECTION_ADDRESSES:
      return {
        ...state,
        available_collection_addresses: action.payload,
      };

    case ShippingActionTypes.SET_COLLECTION_ADDRESS:
      return {
        ...state,
        set_collection_address: true,
        collection_address: action.payload,
      };

    case ShippingActionTypes.UPDATE_COLLECTION_ADDRESS:
      return {
        ...state,
        set_collection_address: false,
        collection_address: [],
        set_collection_option: false,
        collection_option: [],
        cost: 0,
      };

    case ShippingActionTypes.GET_COLLECTION_OPTIONS:
      return {
        ...state,
        collection_options: action.payload,
      };

    case ShippingActionTypes.SET_COLLECTION_OPTION:
      return {
        ...state,
        set_collection_option: true,
        collection_option: action.payload,
        cost: action.payload.cost,
      };

    case ShippingActionTypes.UPDATE_COLLECTION_OPTION:
      return {
        ...state,
        set_collection_option: false,
        collection_option: [],
        cost: 0,
      };

    case ShippingActionTypes.RESET_SHIPPING:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default shippingReducer;

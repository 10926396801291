import React from 'react';
import { connect } from 'react-redux';

import { selectCartItemsCount } from '../../redux/cart/cart.selectors';

import { ReactComponent as ShoppingIcon } from '../../assets/shopping-bag.svg';

import './cart-icon-mobile.styles.scss';

const CartIconMobile = ({ itemCount }) => (
  <div className='mobile-cart-icon'>
    <ShoppingIcon className='shopping-icon' />
    <span className='item-count'>{ itemCount } </span>
  </div>
);

const mapStateToProps = state => ({
  itemCount: selectCartItemsCount(state)
});



export default connect(
  mapStateToProps
)(CartIconMobile);

import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import axios from 'axios';

import PageInfo from '../shared/page-info.component';

const API_URI = process.env.REACT_APP_API_URI;

class ProductList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            categoryId: props.categoryId,
        }
    }

    componentDidMount = () => {
        let self = this;
        

        const categoryId = self.state.categoryId;

        

        axios.get(API_URI + '/categories/' + categoryId + '/published')
            .then(function (response) {
                console.log(response);


              //  history.push("/order-complete");

                self.setState({...self.state, ...response.data.data});
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            });
    }

    render() {

        console.log(this.state);

        //return <Redirect to="/test" />

        if(this.state.status === 'draft') {
            //return <Redirect to="/" />
        }

     

        return this.state.title !== undefined ? ( 
             <article role="main">

<Helmet>
        <title>{this.state.title} | Trinity House</title>
      </Helmet>

                <PageInfo title={this.state.title} feature={this.state.description}/>
                <section className="container">
                    <div id="products-list-container">
                        <ul id="products-list" className="products-list card-columns">
                            {this.state.products.map(product => (
                                <li key={'product-' + product.id} className="products-list-item card">
                                    <Link className="products-list-link" to={this.state.status === 'unlisted' ? '/unlisted-products/'+ product.id : '/products/' + product.id} >
                                        <h3 className="text-standfirst">{product.title}</h3>
                                        {product.feature_image_location ? 
                                        <img src={product.feature_image_location} alt={product.feature_image_description} /> : 
                                        <img src="/images/product-placeholder-image.png" alt={product.title} /> }
                                        <div>
                                            <p className="description">{product.feature}</p>
                                            <div className="from d-flex">From <div className="price d-flex ml-2">£{product.price_from}</div></div>
                                        </div>
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </section>
            </article> 
        ) : (<article role="main"><PageInfo title={'Loading ...'} feature={false}/></article>);
    }
}

export default ProductList;
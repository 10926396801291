import React from "react";

const Footer = () => {
  return (
    <footer id="footer" className="bg-light">
      <section className="container py-5">
        <div className="row d-flex justify-content-between">
          <div className="col-12 col-md-7 mb-5 mb-md-0">
            <section className="about">
              <h3>About Trinity House</h3>

              <img src="/images/logo.png" alt="Trinity House logo" />

              <p>
                Trinity House is a charity dedicated to safeguarding shipping
                and seafarers, providing education, support and welfare to the
                seafaring community with a statutory duty as a General
                Lighthouse Authority to deliver a reliable, efficient and
                cost-effective aids to navigation service for the benefit and
                safety of all mariners.
              </p>
            </section>

            <nav>
              <ul className="footer-links">
                <li>
                  <a href="/accessibility">Accessibility</a>
                </li>
                <li>
                  <a href="/legal-notices">Legal notices</a>
                </li>
                <li>
                  <a href="/site-map">Site map</a>
                </li>
                <li>
                  <a href="/rss">RSS</a>
                </li>
              </ul>

              <p className="my-2">
                &copy; The Corporation of Trinity House 2016
              </p>
              <p>
                Design by{" "}
                <a
                  href="https://www.uxblondon.com/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  UXB London
                </a>{" "}
                - Powered by{" "}
                <a
                  href="http://www.boomcms.net"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  BoomCMS
                </a>
              </p>
            </nav>
          </div>

          <div className="col-12 col-md-3">
            <section className="contact vcard">
              <h3>Follow us</h3>
              <nav>
                <ul className="social">
                  <li>
                    <a
                      href="https://twitter.com/trinityhouse_uk"
                      className="fa fa-twitter"
                    >
                      <span>Twitter</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="http://www.facebook.com/trinityhouseuk"
                      className="fa fa-facebook"
                    >
                      <span>Facebook</span>
                    </a>
                  </li>
                </ul>
              </nav>

              <h3>Contact us</h3>
              <div className="adr">
                <p className="fn">Trinity House</p>
                <p className="street-address">Tower Hill</p>
                <p className="region">London</p>
                <p className="postal-code">EC3N 4DH</p>
                <a className="footer-tel" href="tel:+442074816900">
                  020 7481 6900
                </a>
                <a
                  className="footer-email"
                  href="mailto:enquiries@trinityhouse.co.uk"
                >
                  enquiries@trinityhouse.co.uk
                </a>
              </div>
            </section>
          </div>
        </div>
      </section>
    </footer>
  );
};

export default Footer;

import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { withRouter } from "react-router-dom";

import CartItem from "./cart-item.component";
import {
  selectCartItems,
  selectCartTotal,
} from "../../redux/cart/cart.selectors";
import { selectCurrentShipping } from "../../redux/shipping/shipping.selectors";
import { toggleCartHidden } from "../../redux/cart/cart.actions";

import "./cart-dropdown.styles.scss";

const MiniCart = ({ cartItems, total, shipping, history }) => (
  <div className="bg-white p-3">
    <h6 className="mb-3">BASKET ({cartItems.length} items)</h6>
    {cartItems.length ? (
      <React.Fragment>
        <ul className="d-block w-100 mini-cart-list">
          {cartItems.map((cartItem) => (
            <li key={cartItem.id} className="mb-2 mini-cart-dropdown">
              <div className="p-1 pr-2">
                {cartItem.image === null ? (
                  <img
                  className="mini-item-image"
                    src="/images/product-placeholder-thumbnail.png"
                    alt={cartItem.title}
                  />
                ) : (
                  <img className="mini-item-image" src={cartItem.image} alt={cartItem.title} />
                )}
              </div>

              <div className="mini-cart-text">
                {cartItem.title}
                <br />
                <small>
                  {cartItem.quantity} x £{cartItem.price}
                </small>
              </div>
            </li>
          ))}
        </ul>

        <ul className="d-block w-100">
          <li>
            <span>Basket total: </span>
            <strong className="float-right">£{total.toFixed(2)}</strong>
          </li>
          <li>
            <span>
              {shipping.method === "collection"
                ? "Collection charge:"
                : "Total delivery charge:"}{" "}
            </span>
            <strong className="float-right">{shipping.cost ? '£'+Number(shipping.cost).toFixed(2) : '--'}</strong>
          </li>
        </ul>
      </React.Fragment>
    ) : (
      <span className="empty-message">Your cart is empty</span>
    )}

    <div
      className="btn btn-grey mt-3"
      onClick={() => {
        history.push("/cart");
      }}
    >
      Edit basket
    </div>
  </div>
);

const mapStateToProps = createStructuredSelector({
  cartItems: selectCartItems,
  total: selectCartTotal,
  shipping: selectCurrentShipping,
});

export default withRouter(connect(mapStateToProps)(MiniCart));

import React, { Component } from "react";
import axios from "axios";
import ProductVariants from "./product-variants.component";
import ProductSlideshow from "./product-slideshow.component";
import PageInfo from "../shared/page-info.component";

import { connect } from "react-redux";

import { Helmet } from "react-helmet";

import { addItem } from "../../redux/cart/cart.actions";

import CheckoutButton from "../cart/checkout-button.component";

const API_URI = process.env.REACT_APP_API_URI;

class LCPDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      productUrl: props.productUrl,
      product: false,
    };
  }

  componentDidMount = () => {
    let self = this;
    const productUrl = self.state.productUrl;

    axios
      .get(API_URI + "/lcp-details?url=" + productUrl)
      .then(function (response) {
        self.setState({ product: response.data.product });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };

  render() {
    const product = this.state.product;

    if (product) {
      return (
        <article role="main">
          <Helmet>
            <title>{product.title} | Trinity House</title>
          </Helmet>

          <div className="container py-5">
            <div className="row">
              <PageInfo
                title={product.title}
                feature={product.feature}
                showSubscribe={false}
              />
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-12 col-md">
                <ProductSlideshow images={product.images} />

                <div className="shop-prices mt-5">
                  <ProductVariants variants={product.variants} />
                </div>
                <CheckoutButton />
              </div>

              <div className="col-12 col-md">
                <div
                  className="content"
                  dangerouslySetInnerHTML={{ __html: product.description }}
                ></div>
              </div>
            </div>
          </div>
        </article>
      );
    } else {
      return <div></div>;
    }
  }
}

const mapDispactToProps = (dispatch) => ({
  addItem: (item) => addItem(dispatch(item)),
});

export default connect(null, mapDispactToProps)(LCPDetails);

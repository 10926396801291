import React from 'react';
import { OrderInfo } from '../components/shared/order-info.component';
import UnlistedCategories from '../components/categories/unlisted-category-list.component';
import ProductList from '../components/products/product-list.component';

const UnlistedCategoryPage = (props) => {

  const categoryId = props.match.params.categoryId;

  return <main role="main" id="main">
    <div className="bg-light">
      <UnlistedCategories />
    </div>
    <ProductList key={'category-' + categoryId} categoryId={categoryId} />
    <OrderInfo />
  </main>
}

export default UnlistedCategoryPage;
import React from "react";
import { connect } from "react-redux";

import { selectCartItemsCount } from "../../redux/cart/cart.selectors";

import { withRouter } from "react-router-dom";

const GotoCartButton = ({ itemCount, history }) => {
  if (itemCount > 0) {
    return (
      <div>
        <strong className="pr-3">You have {itemCount} item(s) in your basket</strong>
        <button
          className="btn btn-grey"
          aria-label="Go to Basket"
          onClick={() => {
            history.push("/cart");
          }}
        >
          Go to Basket
        </button>
      </div>
    );
  } else {
    return (
      <div className="mb-5">
        <p>
          <strong>You have {itemCount} item(s) in the Cart</strong>
        </p>
      </div>
    );
  }
};

const mapStateToProps = (state) => ({
  itemCount: selectCartItemsCount(state),
});

export default withRouter(connect(mapStateToProps)(GotoCartButton));

import React, { Component } from 'react';

import { UnlistedCategory } from './unlisted-category-info.component';

import axios from 'axios';

const API_URI = process.env.REACT_APP_API_URI;

class UnlistedCategories extends Component {
    state = {
        categories: []
    }

    componentDidMount = () => {
        let self = this;

        axios.get(API_URI + '/unlisted')
            .then(function (response) {
                self.setState({ categories: response.data.data });
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            });
    }

    render() {
        return (
            <section id="filters" className="shop-filters py-5">
                <div className="container">
                    <h2 className="text-standfirst">Unlisted Categories</h2>
                    <ul className="shop-filters-list">
                        {
                            this.state.categories.map( (category, index) => {
                                return <UnlistedCategory key={category.id} category={category}/>
                            })
                        }
                    </ul>
                </div>
            </section>


        );
    }
}

export default UnlistedCategories;
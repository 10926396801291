import $ from 'jquery';

class DropdownNavLoader {
	constructor(els) {
		this.$dropdown = $(els.selector).find('#dropdown');

		this.events(els);
	}

	events() {
		var $dropdown = this.$dropdown,
			$header = $('#header');

		$dropdown
			.on('mouseover focus', function() {
				$(this).addClass('hover');
			})
			.on('mouseleave', function() {
				$(this).removeClass('hover');
			});

		$header
			.on('mouseover focus', 'a.dropdown', function() {
				$(this).attr('aria-expanded', true);
			})
			.on('mouseleave blur', 'a.dropdown', function() {
				$(this).attr('aria-expanded', false);
			})
			.on('mouseover focus', 'a.dropdown', function() {
				var $this = $(this);
				var $menu = $('#' + $this.attr('aria-controls'));

				$dropdown.html($menu[0].outerHTML);
				// $dropdown.find('li:nth-of-type(-n+6) a').equalHeights();
				$dropdown.focus();

				if (!$dropdown.hasClass('visible')) {
					var timeout = setTimeout(function() {
						clearTimeout(timeout);

						if ($this.attr('aria-expanded') === 'true') {
							$dropdown.css('top', $header.offset().top + $header.height());
							$dropdown.addClass('visible');
							$dropdown.slideDown(function() {
								// $dropdown.find('li:nth-of-type(-n+6) a').equalHeights();
							});
						}
					}, 100);
				}

			})
			.on('click', '.expand', function(e) {
				e.preventDefault();

				var $this = $(this),
					$subMenu = $this.parent().find('div.dropdown');

				$this.toggleClass('fa-minus');
				$subMenu.slideToggle();
			});

		$('html').on('mousemove', function() {
			var timeout = setTimeout(function() {
				clearTimeout(timeout);

				if ($dropdown.hasClass('visible')
					&&  ! $('a.dropdown[aria-expanded=true]').length
					&& ! $dropdown.hasClass('hover')
					&& ! $('a.touch-menu:active').length
				) {
					$dropdown.removeClass('visible');
					$dropdown.slideUp();
				}
			}, 200);
		});
	}
}

export default {
	new(els) {
		new DropdownNavLoader(els);
	}
}
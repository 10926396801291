import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import PageInfo from "../shared/page-info.component";

const API_URI = process.env.REACT_APP_API_URI;

class LCPList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "Lighthouse cottage payments",
      feature: "Payment pages for internal lighthouse cottage bookings",
      products: [],
    };
  }

  componentDidMount = () => {
    let self = this;
    axios
      .get(API_URI + "/lcp")
      .then(function (response) {
        self.setState({
          products: response.data.products,
        });
      })
      .catch(function (error) {
        // showing axios error
        console.log(error);
      });
  };

  render() {
    const products = this.state.products;
    console.log(this.state);

    return (
      <article role="main">
        <PageInfo
          title={this.state.title}
          feature={this.state.feature}
          showSubscribe={false}
        />

        <section className="container">
          <div id="products-list-container">
            <ul id="products-list" className="products-list card-columns">
              {products.map((product) => (
                <li
                  key={"product-" + product.id}
                  className="products-list-item card"
                >
                  <Link
                    className="products-list-link"
                    to={"/lcp/" + product.url}
                  >
                    <h3 className="text-standfirst">{product.title}</h3>
                    <img src={product.image} alt="" />
                    <div>
                      <p className="description">{product.feature}</p>
                      <div className="from d-flex">
                        From{" "}
                        <div className="price d-flex ml-2">
                          £{product.price}
                        </div>
                      </div>
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </section>
      </article>
    );
  }
}

export default LCPList;

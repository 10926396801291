import React from 'react';
import { Link } from 'react-router-dom';

export const UnlistedCategory = (props) => {
    const category = props.category;
    return <li key={category.id} className="shop-filters-item" >
        <Link to={'/unlisted-categories/' + category.id} className="cta" style={{height: "94px"}}>
            <p>{category.title}</p>
            <span className="fa fa-caret-right ml-auto"></span>
        </Link>
    </li>
}
import CustomerActionTypes from './customer.types';


export const setCustomer = customer => ({
  type: CustomerActionTypes.SET_CUSTOMER,
  payload: customer
});


export const changeCustomer = () => ({
  type: CustomerActionTypes.CHANGE_CUSTOMER
});

export const resetCustomer = () => ({
  type: CustomerActionTypes.RESET_CUSTOMER
});



import React, { Component } from 'react';
import Carousel from '../../js/carousel';

class ProductSlideshow extends Component {

	componentDidMount = () => {
		Carousel.init();
	}

	render() {
		return (
			<div id="shopSlideshow" className="carousel slide shop-slideshow-wrap" data-ride="carousel">
				<div className="carousel-inner">
					{this.props.images.map((image, index) => (
						<div key={'slideshow-item-' + index} className={'carousel-item ' + ((index === 0) ? ' active' : '')}>
							<img className="" src={image.location} alt={image.description} />
						</div>
					))}
				</div>

				{this.props.images.length > 1 ? (
					<div>
<a className="carousel-control-prev" href="#shopSlideshow" role="button" data-slide="prev">
					<span className="carousel-control-prev-icon" aria-hidden="true"></span>
					<span className="sr-only">Previous</span>
				</a>
				<a className="carousel-control-next" href="#shopSlideshow" role="button" data-slide="next">
					<span className="carousel-control-next-icon" aria-hidden="true"></span>
					<span className="sr-only">Next</span>
				</a>
					</div>
				) : ""}
				
			</div>
		);
	}
}

export default ProductSlideshow;



import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import cartReducer from './cart/cart.reducer';
import customerReducer from './customer/customer.reducer';
import shippingReducer from './shipping/shipping.reducer';
import paymentReducer from './payment/payment.reducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['cart', 'customer', 'shipping', 'payment']
}

const rootReducer = combineReducers({
  cart: cartReducer,
  customer: customerReducer,
  shipping: shippingReducer,
  payment: paymentReducer,
});

export default persistReducer(persistConfig, rootReducer);
import React from "react";
import { useSnackbar } from "notistack";
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import { createStructuredSelector } from "reselect";

import axios from "axios";

import {
  setBillingAddress,
  updateBillingAddress,
  resetPayment,
} from "../../../redux/payment/payment.actions";
import { selectCurrentPayment } from "../../../redux/payment/payment.selectors";

import { resetCustomer } from "../../../redux/customer/customer.actions";
import { selectCurrentCustomer } from "../../../redux/customer/customer.selectors";

import { resetCart } from "../../../redux/cart/cart.actions";
import {
  selectCartItems,
  selectCartTotal,
} from "../../../redux/cart/cart.selectors";

import { resetShipping } from "../../../redux/shipping/shipping.actions";
import { selectCurrentShipping } from "../../../redux/shipping/shipping.selectors";

import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import "./CardSectionStyles.css";

const CARD_ELEMENT_OPTIONS = {
  hidePostalCode: true,
};

const API_URI = process.env.REACT_APP_API_URI;

const StripePaymentForm = ({
  customer,
  items,
  cartTotal,
  shipping,
  payment,
  resetCart,
  resetCustomer,
  resetShipping,
  resetPayment,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const totalAmountToPay = Number(cartTotal) + Number(shipping.cost);

  const [loading, setLoading] = React.useState(false);
  const [cardComplete, setCardComplete] = React.useState(false);
  const history = useHistory();

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    setLoading(true);
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    // now create order details

    const customerDetails = {
      name: customer.details.name,
      email: customer.details.email,
    };

    let shippingDetails = {};
    if (shipping.method === "delivery") {
      shippingDetails = {
        method: "delivery",
        delivery_address: shipping.delivery_address,
        deliveries: shipping.deliveries,
        cost: shipping.cost,
      };
    } else if (shipping.method === "collection") {
      shippingDetails = {
        method: "collection",
        collection_address: shipping.collection_address,
        collection_option: shipping.collection_option,
        cost: shipping.cost,
      };
    }

    let paymentDetails = {
      type: "stripe",
      billing: payment.billing_address,
      amount: totalAmountToPay,
    };

    const orderDetails = {
      customer: customerDetails,
      basket_items: items,
      shipping: shippingDetails,
      payment: paymentDetails,
    };

    //get secret

    const result = await axios
      .post(API_URI + "/orders", orderDetails)
      .then((response) => {
        return response.data.data;
      })
      .then(async (order) => {
        console.log(order);

        //  setOrderId(order.id);

        const payment_intend = await axios.post(
          API_URI + "/stripe/payment-intend",
          {
            order_id: order.id,
            order_ref: order.ref,
            amount: totalAmountToPay * 100,
          }
        );

        if (payment_intend.data.status === "success") {
          return {
            order: order,
            secret: payment_intend.data.secret,
          };
        }

        if (payment_intend.data.status === "error") {
          enqueueSnackbar(`${payment_intend.data.message}`, {
            variant: payment_intend.data.status,
            persist: false,
          });

          setLoading(false);

          // remove order
          axios.post(
            API_URI + "/orders/" + order.id + "/ref/" + order.ref + "/remove"
          );

          return false;
        }
      })
      .then(async (payment_intend) => {
        if (payment_intend && payment_intend.secret !== undefined) {
          const pay = await stripe.confirmCardPayment(payment_intend.secret, {
            payment_method: {
              card: elements.getElement(CardElement),
              billing_details: {
                address: {
                  city: payment.billing_address.city,
                  country: payment.billing_address.country,
                  line1: payment.billing_address.address_line_1,
                  line2: payment.billing_address.address_line_2,
                  postal_code: payment.billing_address.postcode,
                  state: payment.billing_address.county,
                },
                email: customer.details.email,
                name: payment.billing_address.name,
                phone: payment.billing_address.phone,
              },
            },
          });

          setLoading(false);
          if (pay.error) {
            // remove order
            axios.post(
              API_URI +
                "/orders/" +
                payment_intend.order.id +
                "/ref/" +
                payment_intend.order.ref +
                "/remove"
            );

            // Show error to your customer (e.g., insufficient funds)
            console.log(pay.error.message);

            enqueueSnackbar(`${pay.error.message}`, {
              variant: "error",
              persist: false,
            });
          } else {
            // The payment has been processed!
            if (pay.paymentIntent.status === "succeeded") {
              enqueueSnackbar(`Your order has been placed successfully.`, {
                variant: "success",
                persist: false,
              });

              axios.post(
                API_URI + "/stripe/" + payment_intend.order.id + "/payment",
                {
                  payment_id: pay.paymentIntent.id,
                  payment_status: pay.paymentIntent.status,
                }
              );

              resetCart();
              resetCustomer();
              resetShipping();
              resetPayment();

              history.push("/order-complete");
              // Show a success message to your customer
              // There's a risk of the customer closing the window before callback
              // execution. Set up a webhook or plugin to listen for the
              // payment_intent.succeeded event that handles any business critical
              // post-payment actions.
            }
            return true;
          }
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });

    console.log(result);
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="form-group row">
          <label htmlFor="phone" className="col-sm-3 col-form-label">
            Basket total
          </label>
          <div className="col-sm-9">
            £{cartTotal ? cartTotal.toFixed(2) : "--"}
          </div>
        </div>

        <div className="form-group row">
          <label htmlFor="phone" className="col-sm-3 col-form-label">
            {shipping.method === "collection"
              ? "Collection charge"
              : "Delivery charge"}
          </label>
          <div className="col-sm-9">
            £{shipping.cost ? Number(shipping.cost).toFixed(2) : "--"}
          </div>
        </div>

        <div className="form-group row">
          <label htmlFor="phone" className="col-sm-3 col-form-label">
            <strong>Order total</strong>
          </label>
          <div className="col-sm-9">
            <strong>£{Number(totalAmountToPay).toFixed(2)}</strong>
          </div>
        </div>

        <div className="form-group row">
          <label htmlFor="phone" className="col-sm-3 col-form-label">
            <strong>Card Details</strong> <span className="text-danger">*</span>
          </label>
          <div className="col-sm-9">
            <label>
              <CardElement
                options={CARD_ELEMENT_OPTIONS}
                onChange={(event) => {
                  if (event.complete) {
                    setCardComplete(true);
                  } else {
                    setCardComplete(false);
                  }
                }}
              />
            </label>
          </div>
        </div>

        <div className="form-group row">
          <div className="offset-sm-3 col-sm-9">
            {loading ? (
              <strong>Please wait while we process your order...</strong>
            ) : (
              ""
            )}
            <button
              disabled={
                !stripe ||
                !payment.set_billing_address ||
                !cardComplete ||
                loading
              }
              className="btn btn-blue mt-2"
            >
              {loading ? "Please wait ..." : "Place order and pay"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  customer: selectCurrentCustomer,
  items: selectCartItems,
  cartTotal: selectCartTotal,
  shipping: selectCurrentShipping,
  payment: selectCurrentPayment,
});

const mapDispatchToProps = (dispatch) => ({
  setBillingAddress: (address) => dispatch(setBillingAddress(address)),
  updateBillingAddress: () => dispatch(updateBillingAddress()),
  resetCart: () => dispatch(resetCart()),
  resetCustomer: () => dispatch(resetCustomer()),
  resetShipping: () => dispatch(resetShipping()),
  resetPayment: () => dispatch(resetPayment()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(StripePaymentForm)
);
